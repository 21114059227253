export const reservoirsYadkin = {
  type: "FeatureCollection",
  name: "Yadkin_UI_Reservoirs_Simple",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:EPSG::4269" } },
  features: [
    {
      type: "Feature",
      properties: {
        OBJECTID: 47877.0,
        COMID: 9224423,
        FDATE: "1999/10/07 00:00:00.000",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        AREASQKM: 0.514,
        ELEVATION: 0.0,
        REACHCODE: "03040103002084",
        FTYPE: "LakePond",
        FCODE: 39004,
        Shape_Leng: 0.110706683350013,
        Shape_Area: 5.1050140561000001e-5,
        ONOFFNET: 1,
        PurpCode: null,
        PurpDesc: null,
        MeanDepth: 0.682862189,
        LakeVolume: 351145.8097,
        MaxDepth: 2.279124311,
        MeanDUsed: 0.682862189,
        MeanDCode: "4",
        LakeArea: 514226.40377600002,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.114084319420215, 35.434910011664726],
              [-80.116350186083423, 35.438253678326134],
              [-80.120240319410698, 35.435231211664131],
              [-80.121807319408276, 35.435895411663125],
              [-80.119708319411529, 35.437841878326708],
              [-80.120911519409674, 35.437819211660099],
              [-80.121079319409318, 35.438781011658705],
              [-80.120043919410989, 35.43878087832536],
              [-80.120519319410221, 35.441071078321727],
              [-80.122729919406822, 35.442376478319602],
              [-80.12426918607099, 35.441117078321668],
              [-80.123737319405166, 35.443178078318454],
              [-80.125696386068796, 35.441758278320719],
              [-80.124604786070506, 35.443704878317646],
              [-80.12519231940297, 35.446178211647236],
              [-80.123848786071733, 35.448353678310411],
              [-80.124240519404452, 35.449865211641452],
              [-80.126339519401199, 35.450414878307242],
              [-80.12404451940472, 35.451010278306399],
              [-80.125807319401986, 35.456414878297949],
              [-80.127850386065575, 35.45987307829256],
              [-80.133784586056265, 35.464270011618964],
              [-80.136108119385995, 35.467636411613853],
              [-80.133840386056249, 35.464934078284614],
              [-80.127514386066025, 35.460995278290909],
              [-80.123708519405341, 35.452842411636766],
              [-80.122701786073549, 35.444414678316491],
              [-80.116349986083378, 35.439398811657611],
              [-80.114084319420215, 35.434910011664726],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 45713.0,
        COMID: 9212064,
        FDATE: "1999/10/05 00:00:00.000",
        RESOLUTION: "Medium",
        GNIS_ID: "981595",
        GNIS_NAME: "Blewett Falls Lake",
        AREASQKM: 3.027,
        ELEVATION: 53.0,
        REACHCODE: "03040104001099",
        FTYPE: "LakePond",
        FCODE: 39009,
        Shape_Leng: 0.190733054519655,
        Shape_Area: 0.00029883224128400001,
        ONOFFNET: 1,
        PurpCode: null,
        PurpDesc: null,
        MeanDepth: 3.49872591,
        LakeVolume: 0.0,
        MaxDepth: 0.0,
        MeanDUsed: 3.49872591,
        MeanDCode: "3",
        LakeArea: 3026716.1224600002,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-79.878982105334671, 34.989737126549407],
              [-79.881033919781999, 34.985630279028669],
              [-79.879114119784902, 34.983065212365943],
              [-79.877333449128002, 34.982515430641854],
              [-79.879865386450376, 34.982126279034162],
              [-79.88147938644795, 34.97937807903844],
              [-79.880588919782667, 34.983569079031838],
              [-79.882230586446781, 34.983591879031792],
              [-79.882313986446661, 34.985470012362271],
              [-79.883538319778097, 34.985309679029172],
              [-79.883872319777595, 34.98311101236601],
              [-79.885152186442212, 34.982675879033252],
              [-79.884595719776485, 34.986065412361427],
              [-79.88690538643948, 34.986111079027978],
              [-79.886905319772893, 34.984622479030179],
              [-79.888992319769557, 34.984530812363744],
              [-79.888992386436314, 34.986271279027733],
              [-79.890717586433652, 34.98503447902965],
              [-79.889409719768992, 34.986729279026861],
              [-79.891218719766186, 34.988103279024926],
              [-79.892749119763835, 34.987645079025583],
              [-79.892526719764078, 34.98959167902251],
              [-79.897063119757092, 34.992408212351563],
              [-79.898203986421947, 34.991950079018864],
              [-79.897090386423713, 34.989568412355879],
              [-79.897674586422738, 34.98826287902466],
              [-79.899428119753452, 34.990003279021892],
              [-79.900652519751532, 34.989384612356275],
              [-79.900736119751343, 34.990758812353988],
              [-79.90641251974256, 34.98821567902462],
              [-79.906719519742126, 34.991582212352853],
              [-79.907526319740839, 34.990597212354317],
              [-79.910336919736437, 34.990734012354039],
              [-79.911506719734632, 34.993344479016798],
              [-79.914846119729475, 34.993137612350438],
              [-79.917239986392417, 34.994671412347998],
              [-79.915012786395891, 34.995290412347003],
              [-79.912395319733264, 34.994168812348732],
              [-79.910500786402906, 34.994993612347514],
              [-79.90916411973825, 34.994536012348192],
              [-79.909526719737698, 34.993413679016669],
              [-79.907243519741257, 34.993757812349372],
              [-79.904266386412587, 34.992178079018515],
              [-79.902735519748319, 34.992521879018],
              [-79.90304098641451, 34.993415012349942],
              [-79.901093186417484, 34.993369612350079],
              [-79.903930186413106, 34.995659279013125],
              [-79.905544386410611, 34.995521479013235],
              [-79.906323386409326, 34.998865079008169],
              [-79.907186119741368, 34.998956479008086],
              [-79.906323719742602, 35.000165279006183],
              [-79.891127586432958, 35.000160212339438],
              [-79.886399386440246, 34.996004679012572],
              [-79.885813586441259, 34.999302612340784],
              [-79.884896986442584, 34.996485612345168],
              [-79.881335786448176, 34.997607812343517],
              [-79.882922786445704, 34.994974212347472],
              [-79.880975319782067, 34.995409212346885],
              [-79.881921786447265, 34.994378679015142],
              [-79.878982105334671, 34.989737126549407],
            ],
            [
              [-79.896228919758414, 34.996530679011698],
              [-79.897926986422419, 34.99934741234074],
              [-79.900264786418802, 34.999232612340847],
              [-79.899624519753161, 34.998316612342308],
              [-79.896228919758414, 34.996530679011698],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 45995.0,
        COMID: 9210308,
        FDATE: "1999/10/05 00:00:00.000",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        AREASQKM: 6.97,
        ELEVATION: 53.0,
        REACHCODE: "03040104001097",
        FTYPE: "LakePond",
        FCODE: 39009,
        Shape_Leng: 0.49056031788388599,
        Shape_Area: 0.00068846130150599998,
        ONOFFNET: 1,
        PurpCode: null,
        PurpDesc: null,
        MeanDepth: 3.49872591,
        LakeVolume: 34975043.17,
        MaxDepth: 13.39886579,
        MeanDUsed: 3.49872591,
        MeanDCode: "4",
        LakeArea: 6969792.35891,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-79.891127586432958, 35.000160212339438],
              [-79.906323719742602, 35.000165279006183],
              [-79.904376186412435, 35.003468879000991],
              [-79.90927458640482, 35.002940879001869],
              [-79.909218586404904, 35.00555167899779],
              [-79.911055319735397, 35.006673412329235],
              [-79.909412986404618, 35.007063078995486],
              [-79.909663119737502, 35.008116612327171],
              [-79.913141586398808, 35.010108012324054],
              [-79.911749586400958, 35.011116012322361],
              [-79.911693519734285, 35.015879478981731],
              [-79.910496519736228, 35.01668127898057],
              [-79.907570586407473, 35.00820867899364],
              [-79.905649786410436, 35.007865678994222],
              [-79.898944186420863, 35.017439878979189],
              [-79.901895319749599, 35.018195012311423],
              [-79.901728919749871, 35.020393612308055],
              [-79.903622186413543, 35.021492412306429],
              [-79.902369386415501, 35.021538478973014],
              [-79.899194986420468, 35.018539078977597],
              [-79.896440786424648, 35.025959478966115],
              [-79.897081386423736, 35.026738078964797],
              [-79.893879919762014, 35.027814878963113],
              [-79.898279586421893, 35.03118061229128],
              [-79.894854719760531, 35.03012781229296],
              [-79.892654986430614, 35.028021278962967],
              [-79.886808786439701, 35.032006678956634],
              [-79.887699919771592, 35.033747078954093],
              [-79.885583719774843, 35.032419012289267],
              [-79.879569719784229, 35.036198012283478],
              [-79.879207786451502, 35.039289612278651],
              [-79.885501186441729, 35.045655812268933],
              [-79.887562386438503, 35.049503012262903],
              [-79.88600278644094, 35.049892478928939],
              [-79.881296319781598, 35.043457612272334],
              [-79.884304119776914, 35.049343012263193],
              [-79.898592319754641, 35.057768812250117],
              [-79.903104586414429, 35.058844212248346],
              [-79.90979131973728, 35.064819878905837],
              [-79.912384319733178, 35.070910678896269],
              [-79.909710186404084, 35.071461212228769],
              [-79.906394986409282, 35.070454278897046],
              [-79.896811186424145, 35.063471478907786],
              [-79.891435186432489, 35.061800478910413],
              [-79.87441811979221, 35.047785878932075],
              [-79.877341986454326, 35.051564678926411],
              [-79.876060786456321, 35.053465412256799],
              [-79.871549986463322, 35.045381212269319],
              [-79.872079586462462, 35.038740012279561],
              [-79.874279386459136, 35.035213278951801],
              [-79.877091786454741, 35.031228478957985],
              [-79.880683319782463, 35.029716878960301],
              [-79.880878186448911, 35.028526078962102],
              [-79.883328186445056, 35.028159612296065],
              [-79.883021919778912, 35.027014612297762],
              [-79.884720119776205, 35.027243478964181],
              [-79.887698919771594, 35.025411078966954],
              [-79.889091719769453, 35.020922278973899],
              [-79.887644719771743, 35.018861412310457],
              [-79.8889535197697, 35.017991012311768],
              [-79.889623319768646, 35.013937478984815],
              [-79.891877919765136, 35.013570678985388],
              [-79.896972319757197, 35.007753012327612],
              [-79.897028186423768, 35.005508612331141],
              [-79.893020719763399, 35.003081879001627],
              [-79.891128319766324, 35.002784412335416],
              [-79.890877986433338, 35.004868412332144],
              [-79.88998718643461, 35.0028304123353],
              [-79.887677119771695, 35.00262447900235],
              [-79.890655119767075, 35.002143212336364],
              [-79.891684386432132, 35.000906412338281],
              [-79.891127586432958, 35.000160212339438],
            ],
            [
              [-79.883496786444709, 35.053671412256449],
              [-79.88310678644541, 35.05426667892209],
              [-79.888872319769746, 35.057793012249988],
              [-79.892047786431533, 35.061204878911383],
              [-79.898983786420729, 35.063791678907251],
              [-79.906311186409368, 35.069744412231501],
              [-79.908623519739194, 35.070385078897175],
              [-79.906700186408784, 35.066858812235921],
              [-79.901936119749507, 35.063150012241692],
              [-79.889791186434991, 35.056922678917999],
              [-79.891852386431879, 35.058388078915812],
              [-79.890236986434388, 35.058113612249542],
              [-79.883496786444709, 35.053671412256449],
            ],
            [
              [-79.877425719787595, 35.039495812278346],
              [-79.879096386451693, 35.044098878937916],
              [-79.88185338644729, 35.047098812266654],
              [-79.881379786448065, 35.045404212269261],
              [-79.877425719787595, 35.039495812278346],
            ],
            [
              [-79.876061319789699, 35.040205678944062],
              [-79.876785186455265, 35.043847012271726],
              [-79.881407786448051, 35.048472878931193],
              [-79.87803831978664, 35.044534078937318],
              [-79.876061319789699, 35.040205678944062],
            ],
            [
              [-79.877676119787225, 35.049320278929713],
              [-79.879820586450535, 35.051839412259199],
              [-79.8826891197794, 35.053694278923047],
              [-79.88107378644861, 35.051656078926158],
              [-79.877676119787225, 35.049320278929713],
            ],
            [
              [-79.877202786454632, 35.046984412266795],
              [-79.877091519788053, 35.048015012265182],
              [-79.879486319784405, 35.049686678929334],
              [-79.87814971978645, 35.048541678931031],
              [-79.878873719785361, 35.048106612264974],
              [-79.877202786454632, 35.046984412266795],
            ],
            [
              [-79.878288919786257, 35.035877478950624],
              [-79.877899119786775, 35.036862078949127],
              [-79.878066186453225, 35.038190412280471],
              [-79.878511719785934, 35.037297212281885],
              [-79.878288919786257, 35.035877478950624],
            ],
            [
              [-79.900876519751193, 35.058936278914985],
              [-79.900709519751388, 35.059554678913912],
              [-79.901740119749775, 35.059623212247232],
              [-79.901350119750305, 35.059119412248037],
              [-79.900876519751193, 35.058936278914985],
            ],
            [
              [-79.905056119744643, 35.064614878906127],
              [-79.905056386411331, 35.065370678904856],
              [-79.905780719743575, 35.065576612237862],
              [-79.905668986410433, 35.065049878905313],
              [-79.905056119744643, 35.064614878906127],
            ],
            [
              [-79.898843319754349, 35.058822212248458],
              [-79.898731919754482, 35.059097012247946],
              [-79.9000969197524, 35.059554812247256],
              [-79.899455986420037, 35.058867878915009],
              [-79.898843319754349, 35.058822212248458],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 45726.0,
        COMID: 9211452,
        FDATE: "1999/10/07 00:00:00.000",
        RESOLUTION: "Medium",
        GNIS_ID: "1024351",
        GNIS_NAME: "Lake Tillery",
        AREASQKM: 19.798,
        ELEVATION: 85.0,
        REACHCODE: "03040104001081",
        FTYPE: "LakePond",
        FCODE: 39009,
        Shape_Leng: 1.22404756041735,
        Shape_Area: 0.0019613746410019998,
        ONOFFNET: 1,
        PurpCode: null,
        PurpDesc: null,
        MeanDepth: 3.743265696,
        LakeVolume: 74111010.61,
        MaxDepth: 15.85581166,
        MeanDUsed: 3.743265696,
        MeanDCode: "4",
        LakeArea: 19798490.6941,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.080069519473057, 35.219604811998806],
              [-80.081882586136885, 35.221094211996615],
              [-80.08604158613042, 35.219813278665129],
              [-80.086570919462872, 35.221576878662347],
              [-80.084058586133494, 35.222514878660945],
              [-80.084644119465963, 35.223729011992418],
              [-80.086401719463197, 35.224943278657236],
              [-80.088160186127084, 35.2245088119912],
              [-80.088661586126307, 35.226501411988181],
              [-80.091424519455359, 35.22666267865452],
              [-80.092681586120079, 35.224327011991591],
              [-80.09078511945637, 35.221532478662596],
              [-80.09229171945401, 35.222403211994447],
              [-80.093603986118694, 35.22102947866324],
              [-80.094355919450891, 35.224968878657194],
              [-80.094524319450613, 35.222610011994163],
              [-80.095807919448589, 35.223137078659988],
              [-80.097259986112988, 35.221007811996742],
              [-80.096061319448211, 35.21757221200204],
              [-80.09703878611333, 35.215923612004531],
              [-80.098961919443695, 35.222222011994745],
              [-80.101752586105988, 35.222703611994064],
              [-80.099491719442881, 35.223733611992429],
              [-80.100244386108386, 35.225886478655696],
              [-80.098485919444443, 35.226458611988221],
              [-80.099685519442573, 35.227695611986292],
              [-80.096308386114458, 35.227648878652985],
              [-80.09502311944982, 35.230625611981793],
              [-80.098567786110891, 35.230924278647933],
              [-80.096976119446822, 35.2325498786455],
              [-80.099459186109584, 35.23555061197419],
              [-80.102530319438131, 35.233352811977397],
              [-80.102947586104165, 35.237795678637212],
              [-80.104733586101361, 35.239238878634978],
              [-80.108083919429475, 35.237453411971217],
              [-80.106464119432076, 35.239788878634272],
              [-80.107300986097357, 35.242010478630675],
              [-80.104147319435583, 35.239674011967679],
              [-80.101271986106724, 35.239787811967517],
              [-80.103308319436962, 35.244528811960095],
              [-80.100042719442001, 35.242718878629603],
              [-80.099092386110215, 35.245993478624541],
              [-80.101136719440319, 35.251010011950143],
              [-80.109372586094139, 35.251057211949956],
              [-80.114837386085696, 35.246019478624532],
              [-80.115311586084943, 35.247393611955772],
              [-80.113887719420461, 35.248149211954512],
              [-80.114194519419925, 35.249408811952662],
              [-80.116427919416594, 35.249638078618943],
              [-80.117628719414711, 35.247187678622765],
              [-80.117963586080919, 35.248378678620782],
              [-80.119973586077776, 35.248859811953423],
              [-80.124468586070805, 35.245447878625384],
              [-80.120727186076522, 35.249936211951706],
              [-80.117887719414284, 35.251172478616581],
              [-80.118055186080767, 35.252271811948162],
              [-80.120092919410922, 35.255523878609779],
              [-80.123555119405523, 35.255592811942961],
              [-80.125956186068436, 35.252867478613837],
              [-80.128329319398119, 35.253485811946291],
              [-80.126486586067585, 35.253829278612386],
              [-80.124029719404746, 35.256577478608165],
              [-80.122354386074107, 35.256371411941643],
              [-80.121935386074711, 35.259119611937592],
              [-80.12031598607723, 35.258524078605092],
              [-80.120735119409915, 35.257218678607217],
              [-80.115709719417737, 35.25401207861205],
              [-80.115821586084166, 35.252890011947045],
              [-80.113895186087234, 35.252615011947569],
              [-80.112750319422275, 35.253668278612565],
              [-80.111522186090895, 35.251698611949053],
              [-80.109679186093729, 35.253645078612635],
              [-80.111716186090518, 35.259027278604265],
              [-80.113084319421773, 35.259256478603845],
              [-80.114647386086062, 35.262417011932428],
              [-80.116155119417044, 35.262806411931763],
              [-80.115066186085414, 35.263264411931118],
              [-80.115596319417875, 35.26516527859485],
              [-80.116796986082704, 35.265783678593777],
              [-80.118695986079615, 35.264684611928885],
              [-80.116545386083033, 35.268623478589461],
              [-80.11341858608796, 35.263974211930019],
              [-80.11059798609233, 35.265302211927974],
              [-80.112246119423105, 35.26218781193262],
              [-80.106969719431277, 35.258820411937904],
              [-80.10532311943382, 35.255843011942545],
              [-80.10428998610206, 35.256346611941751],
              [-80.103955319435954, 35.255430478609867],
              [-80.103787319436151, 35.256758811941211],
              [-80.102167986105371, 35.256575278608125],
              [-80.10219498610536, 35.259689878603297],
              [-80.100798319440855, 35.261636278600236],
              [-80.101495719439754, 35.263377011930856],
              [-80.099792319442429, 35.263628611930528],
              [-80.098115986111679, 35.266216078593175],
              [-80.100377386108221, 35.267888278590647],
              [-80.100907319440694, 35.269651878587865],
              [-80.097305719446297, 35.267658611924332],
              [-80.098645186110787, 35.270246811920174],
              [-80.09643878611422, 35.270864611919251],
              [-80.097611119445787, 35.272605478583216],
              [-80.096186319448009, 35.273933278581183],
              [-80.096212919447964, 35.277277078575935],
              [-80.096966186113377, 35.279384211905949],
              [-80.098278719444806, 35.279659278572296],
              [-80.098890119443809, 35.288064278559318],
              [-80.100034986108653, 35.288957678557949],
              [-80.101822586105925, 35.288523011891868],
              [-80.102967386104183, 35.290103411889447],
              [-80.105481786100199, 35.288180078559151],
              [-80.104950386101109, 35.290447278555575],
              [-80.10668178609842, 35.292073611886337],
              [-80.110732519425426, 35.289852678556485],
              [-80.114363519419783, 35.290906611888204],
              [-80.115145319418559, 35.292807478551936],
              [-80.110424919425895, 35.291478611887214],
              [-80.109837986093396, 35.293013011884852],
              [-80.107016519431227, 35.29392861188353],
              [-80.10187671943919, 35.294088011883218],
              [-80.099894519442273, 35.29140807855407],
              [-80.101093986107003, 35.29559927854757],
              [-80.09805051944511, 35.292461211885723],
              [-80.098637719444241, 35.29113301188778],
              [-80.097325186112869, 35.29007927855605],
              [-80.096151719448073, 35.291086678554507],
              [-80.096261586114565, 35.295667011880823],
              [-80.094558386117171, 35.29374281188376],
              [-80.093412386118985, 35.295391411881269],
              [-80.093746919451746, 35.297063278545238],
              [-80.092378386120572, 35.296513278546058],
              [-80.092517519453679, 35.297910278543895],
              [-80.089862586124525, 35.300085211873863],
              [-80.090336986123702, 35.301344878538714],
              [-80.089024319459099, 35.300634611873136],
              [-80.08488791946553, 35.304480611867177],
              [-80.08499891946542, 35.30571741186526],
              [-80.083546519467632, 35.30514421186615],
              [-80.082036919469999, 35.306746878530191],
              [-80.081111519471392, 35.312861211854113],
              [-80.082870786135231, 35.314487811851563],
              [-80.08739791946158, 35.312909211853992],
              [-80.08398758613356, 35.315885211849434],
              [-80.084405519466316, 35.318175611845845],
              [-80.087869719460912, 35.318955411844627],
              [-80.085494186131314, 35.319916411843167],
              [-80.083787786133939, 35.323557078504109],
              [-80.085462919464703, 35.326374478499872],
              [-80.083116319468274, 35.324908011835362],
              [-80.082892186135325, 35.325869878500612],
              [-80.081215519471243, 35.326006611833691],
              [-80.076796119478047, 35.332600478490122],
              [-80.071735119485993, 35.336399878484201],
              [-80.069857386155491, 35.343475411806651],
              [-80.073322119483464, 35.345148678470707],
              [-80.073854119482689, 35.343774878472857],
              [-80.075950719479351, 35.343203278473709],
              [-80.076625586144928, 35.33695161181663],
              [-80.078806319475007, 35.33569287848519],
              [-80.08196211947012, 35.339587278479371],
              [-80.084756386132426, 35.340733411810902],
              [-80.082268986136228, 35.340503478477899],
              [-80.077911186143012, 35.336952078483364],
              [-80.078073386142762, 35.345540211803382],
              [-80.073711919482832, 35.347164278467517],
              [-80.069575919489353, 35.346223411802384],
              [-80.069740986155693, 35.349658678463584],
              [-80.072422719484905, 35.352133211793102],
              [-80.069767186155673, 35.352040411793212],
              [-80.070409319488022, 35.353162878458249],
              [-80.067719319492198, 35.361772411778134],
              [-80.06671178616034, 35.362916878443059],
              [-80.056672719509379, 35.365911411771719],
              [-80.053621119514105, 35.370398278431423],
              [-80.054234319513171, 35.372322278428442],
              [-80.061107986169077, 35.377616478420293],
              [-80.061189186168974, 35.380685211748869],
              [-80.057189319508495, 35.381988278413417],
              [-80.056214986176713, 35.377315878420802],
              [-80.052246519516189, 35.375137878424084],
              [-80.056073986176955, 35.37855247841884],
              [-80.053949786180169, 35.377589278420317],
              [-80.056518919509529, 35.381438278414294],
              [-80.051231586184485, 35.383610678410889],
              [-80.055289119511428, 35.38068187841543],
              [-80.048166319522579, 35.373074211760581],
              [-80.049372519520603, 35.369136078433428],
              [-80.053823586180386, 35.363092878442842],
              [-80.061905919501214, 35.35993727844766],
              [-80.063836786164927, 35.35737341178509],
              [-80.064321386164067, 35.345762811803013],
              [-80.063566986165256, 35.34539601180353],
              [-80.065502519495681, 35.336809011816911],
              [-80.062791519499797, 35.33685341181689],
              [-80.062177986167399, 35.335181278486061],
              [-80.059047119505635, 35.336187211817901],
              [-80.06257038616684, 35.33380741182151],
              [-80.062237319500639, 35.331013278492549],
              [-80.064303186164182, 35.333670878488419],
              [-80.064022786164514, 35.334747078486885],
              [-80.065838786161805, 35.335480878485612],
              [-80.070286519488263, 35.330101211827355],
              [-80.071515386152953, 35.330926278492655],
              [-80.074255319482063, 35.329164078495523],
              [-80.078031919476132, 35.322959478505084],
              [-80.080156119472917, 35.321861078506743],
              [-80.078817119474934, 35.318173411845862],
              [-80.077643919476827, 35.317692011846532],
              [-80.078176519475846, 35.314966878517566],
              [-80.077061586144396, 35.310775611857252],
              [-80.079552119473817, 35.304181011867627],
              [-80.082906586135266, 35.300518011873237],
              [-80.076842919478054, 35.30312647853583],
              [-80.07334878615012, 35.306170811864547],
              [-80.070975186153817, 35.304818611866551],
              [-80.073908386149242, 35.304980211866393],
              [-80.075362186146947, 35.30333187853563],
              [-80.073548119483064, 35.300789078539424],
              [-80.076452319478676, 35.30218727853736],
              [-80.082655919469062, 35.299235478541789],
              [-80.082964119468556, 35.297655411877656],
              [-80.081400986137623, 35.295341811881258],
              [-80.083412186134467, 35.295525678547676],
              [-80.084527519466121, 35.299144478542075],
              [-80.086176119463516, 35.298595478542836],
              [-80.08802331946066, 35.291244611887578],
              [-80.089811186124621, 35.291039211888005],
              [-80.091963186121234, 35.287902211892913],
              [-80.091519119455199, 35.28158127856932],
              [-80.088613186126395, 35.284351478564929],
              [-80.089927986124337, 35.28013801190491],
              [-80.089090919459011, 35.278465878574082],
              [-80.083868319467058, 35.278601678573978],
              [-80.089119919458994, 35.27631321191086],
              [-80.087835919460872, 35.274892878579749],
              [-80.084596586132648, 35.274662811913402],
              [-80.085769786130868, 35.274067811914279],
              [-80.086077986130306, 35.272052611917331],
              [-80.086858919462372, 35.273953678581108],
              [-80.088534386126526, 35.274297678580581],
              [-80.089849586124501, 35.268618611922761],
              [-80.088649319459705, 35.267610478590939],
              [-80.090157319457376, 35.267542211924479],
              [-80.087114519462091, 35.265686211927402],
              [-80.086724386129276, 35.264083011929756],
              [-80.088426719460017, 35.265755411927273],
              [-80.090269719457183, 35.265733011927182],
              [-80.094489119450657, 35.25858887860494],
              [-80.091781186121523, 35.257718011939744],
              [-80.094377986117365, 35.257237678607169],
              [-80.093318786119141, 35.253092211946921],
              [-80.091224319455591, 35.254351278611523],
              [-80.088460386126712, 35.254121411945221],
              [-80.089968786124246, 35.25224387861482],
              [-80.091532186121867, 35.252313011948161],
              [-80.091141919455765, 35.251213678616409],
              [-80.093263986119155, 35.250710411950593],
              [-80.09230811945406, 35.246930478622971],
              [-80.089544519458343, 35.246677678623371],
              [-80.089740386124731, 35.245761678624888],
              [-80.092364519453895, 35.245785411958195],
              [-80.091529586121851, 35.240472078633104],
              [-80.090664519456595, 35.239853478634132],
              [-80.091110186122592, 35.241617011964706],
              [-80.090106386124148, 35.239578478634485],
              [-80.08898958612582, 35.239875878633995],
              [-80.08773211946118, 35.24239447863016],
              [-80.08756591946144, 35.239875278634145],
              [-80.0897437861247, 35.238960011968857],
              [-80.088935186125923, 35.237127611971573],
              [-80.086673719462681, 35.238042878636918],
              [-80.086730719462707, 35.235913078640067],
              [-80.084776719465594, 35.235981078640009],
              [-80.084916986132157, 35.234813211975165],
              [-80.083187319468209, 35.233323878644171],
              [-80.079419519474072, 35.232704078645099],
              [-80.079112119474473, 35.233391011977403],
              [-80.082460786135925, 35.234468678642429],
              [-80.079502119473943, 35.234650811975371],
              [-80.080728919471994, 35.236483411972642],
              [-80.07880318614167, 35.236162011973079],
              [-80.077685519476688, 35.237970611970297],
              [-80.081062786138148, 35.238292678636412],
              [-80.0823735861361, 35.240377211966575],
              [-80.08005751947303, 35.239002078635451],
              [-80.081647186137275, 35.241521878631488],
              [-80.080362586139245, 35.242254211963768],
              [-80.080695586138745, 35.24534607862546],
              [-80.077680319476713, 35.245917278624631],
              [-80.078154119476039, 35.247222811956021],
              [-80.076478786145287, 35.247634411955403],
              [-80.076149786145777, 35.25107107861669],
              [-80.072993986150664, 35.25265001194748],
              [-80.07388138614931, 35.249213411952837],
              [-80.075361386146994, 35.248733078620262],
              [-80.073186586150371, 35.244770078626516],
              [-80.074497786148356, 35.245915811958014],
              [-80.076117386145768, 35.245321078625523],
              [-80.07910678614121, 35.241612478631339],
              [-80.078521386142086, 35.240261078633409],
              [-80.075841586146225, 35.240282811966722],
              [-80.075786386146376, 35.239366811968239],
              [-80.073384919483431, 35.240739678632679],
              [-80.075200586147275, 35.238771011968993],
              [-80.074785319481236, 35.233847078643407],
              [-80.071656586152756, 35.237509811971051],
              [-80.070206319488364, 35.235837411973534],
              [-80.071964986152238, 35.235471811974207],
              [-80.072748186151046, 35.233227878644414],
              [-80.069511786156113, 35.231279611980653],
              [-80.073027986150635, 35.232151611979305],
              [-80.073140786150475, 35.230502678648577],
              [-80.069040786156847, 35.226653411987968],
              [-80.06956938615599, 35.228943678651035],
              [-80.068286386157922, 35.227752278652872],
              [-80.066108919494695, 35.228415211985237],
              [-80.064851719496687, 35.229971878649451],
              [-80.06518558616267, 35.23123147864743],
              [-80.062756519499828, 35.232146411979386],
              [-80.063062586165984, 35.233383211977468],
              [-80.061107386169056, 35.234893478641709],
              [-80.057227586175145, 35.234822478641888],
              [-80.060997986169241, 35.232259878645834],
              [-80.059353319505135, 35.229900011982863],
              [-80.062309986167293, 35.232077278646216],
              [-80.062200186167445, 35.229833011983033],
              [-80.063484719498717, 35.229123678650694],
              [-80.062676186166698, 35.228046878652378],
              [-80.063736586165078, 35.228162011985603],
              [-80.063291119499013, 35.226970878654072],
              [-80.064490786163901, 35.227452478653277],
              [-80.065971519494951, 35.225506678656416],
              [-80.064799319496728, 35.225620611989598],
              [-80.063238386165779, 35.223146411993412],
              [-80.06591651949492, 35.224636411991071],
              [-80.067174319493063, 35.222049211994999],
              [-80.065305919495927, 35.220239078664463],
              [-80.066924586160098, 35.220285811997769],
              [-80.066313386161028, 35.216827278669768],
              [-80.063494186165428, 35.217535812002041],
              [-80.064387786164048, 35.216872078669724],
              [-80.062964986166264, 35.216390412003705],
              [-80.065002119496341, 35.216299878670725],
              [-80.064612786163593, 35.214696612006378],
              [-80.065867786161732, 35.21561327867164],
              [-80.063279186165801, 35.20745901201775],
              [-80.070730786154115, 35.205447678687449],
              [-80.071790186152498, 35.206730678685403],
              [-80.073408719483325, 35.206639812018864],
              [-80.07494091948098, 35.210190212013515],
              [-80.077730919476608, 35.210901278678989],
              [-80.076223586145659, 35.21172507867783],
              [-80.076640986145037, 35.21346581200828],
              [-80.079319586140798, 35.213856278674427],
              [-80.077225986144128, 35.214931812006171],
              [-80.0786757194752, 35.21697061200291],
              [-80.081159119471351, 35.217704412001808],
              [-80.079623586140372, 35.218436678667388],
              [-80.080069519473057, 35.219604811998806],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 49392.0,
        COMID: 166737840,
        FDATE: "2008/11/06 00:00:00.000",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        AREASQKM: 84.196,
        ELEVATION: 190.0,
        REACHCODE: "03040103008815",
        FTYPE: "LakePond",
        FCODE: 39009,
        Shape_Leng: 5.3890187434506958,
        Shape_Area: 0.0083739396915450005,
        ONOFFNET: 1,
        PurpCode: null,
        PurpDesc: null,
        MeanDepth: 2.876627677,
        LakeVolume: 242199648.5,
        MaxDepth: 15.46737744,
        MeanDUsed: 2.876627677,
        MeanDCode: "4",
        LakeArea: 84195688.8442,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.183718585978738, 35.650702677996435],
              [-80.182793119313601, 35.65148167799515],
              [-80.178332119320544, 35.651758477994804],
              [-80.177269119322204, 35.65714061131979],
              [-80.176005919324098, 35.656041811321415],
              [-80.171572985997614, 35.657234411319564],
              [-80.171687985997437, 35.662547277977978],
              [-80.174132585993675, 35.668340211302279],
              [-80.173798185994144, 35.672828811295346],
              [-80.172082119330184, 35.664883011307722],
              [-80.169863186000214, 35.660830411314009],
              [-80.167983386003129, 35.661586677979471],
              [-80.167030919337947, 35.664793077974423],
              [-80.164029186009373, 35.667358877970571],
              [-80.166275586005895, 35.670243611299384],
              [-80.165743186006694, 35.672098811296564],
              [-80.16582691933985, 35.670610211298822],
              [-80.163552786010086, 35.668641411301792],
              [-80.163635519343245, 35.665343677973624],
              [-80.166469319338887, 35.664152077975416],
              [-80.167449986004044, 35.660877011313971],
              [-80.169946319333519, 35.658631877984078],
              [-80.168934319335108, 35.655174211322787],
              [-80.170085585999971, 35.656731011320346],
              [-80.175752585991177, 35.654919877989755],
              [-80.177910785987763, 35.6509342113294],
              [-80.185229585976401, 35.644816478005566],
              [-80.184918185976926, 35.640832011344912],
              [-80.186826785974006, 35.642205078009567],
              [-80.193443785963723, 35.637323811350427],
              [-80.195573585960346, 35.634643278021258],
              [-80.1956279859603, 35.632490611358037],
              [-80.199724519287258, 35.633702011356092],
              [-80.20684451927616, 35.628476078030928],
              [-80.205215919278714, 35.626668011367087],
              [-80.198738919288871, 35.629397078029513],
              [-80.205466519278389, 35.624606678036912],
              [-80.20540871927841, 35.622889211372922],
              [-80.207316385942079, 35.623300211372282],
              [-80.217995919258954, 35.617201078048367],
              [-80.229348919241318, 35.612680611388726],
              [-80.231226519238362, 35.611648411390377],
              [-80.231449319237981, 35.610320011392332],
              [-80.232683985902781, 35.610891411391492],
              [-80.234168785900522, 35.609790811393168],
              [-80.234899385899382, 35.610935211391393],
              [-80.235036385899093, 35.608553411395064],
              [-80.233342319235078, 35.599715078075405],
              [-80.228120919243167, 35.594818811416474],
              [-80.223997519249622, 35.593310611418701],
              [-80.217654919259417, 35.58777347809405],
              [-80.217795985925932, 35.588597811426098],
              [-80.211708385935367, 35.585098411431431],
              [-80.216197585928342, 35.58841581142633],
              [-80.214879519263718, 35.587981611426983],
              [-80.20643531927692, 35.582514078102236],
              [-80.206604985943272, 35.58393387809997],
              [-80.199344119287844, 35.584007011433187],
              [-80.204249919280301, 35.583683478100397],
              [-80.20621071927718, 35.582124878102888],
              [-80.205591185944797, 35.579239811440516],
              [-80.203824319280898, 35.578210411442228],
              [-80.205085719278941, 35.578163811442266],
              [-80.203736119281075, 35.573950878115454],
              [-80.205048519278989, 35.568797278123554],
              [-80.201878585950624, 35.565982411461277],
              [-80.199020519288354, 35.566694078126829],
              [-80.190442985968389, 35.565714211461682],
              [-80.200924585952066, 35.564517411463555],
              [-80.203392919281612, 35.566737211459952],
              [-80.205130519278839, 35.566529878127085],
              [-80.208650719273464, 35.555145678144754],
              [-80.204525919279718, 35.54978961148629],
              [-80.199507719287624, 35.547319478156908],
              [-80.196981185958236, 35.54207661149826],
              [-80.192578119298332, 35.537086611506027],
              [-80.195373785960669, 35.52996281151718],
              [-80.192038585965918, 35.52833867818623],
              [-80.19119831930044, 35.528385011519504],
              [-80.191703919299641, 35.53005641151691],
              [-80.188258719305111, 35.53113461151537],
              [-80.186941319307039, 35.530150478183543],
              [-80.187784119305718, 35.533127211512181],
              [-80.191289319300324, 35.537041478172853],
              [-80.190028719302347, 35.537408611505498],
              [-80.185600185975886, 35.535006278175899],
              [-80.185434585976111, 35.538624678170265],
              [-80.183500385979187, 35.537320278172274],
              [-80.179522719318697, 35.538627478170326],
              [-80.1781229859875, 35.540483011500839],
              [-80.176946119322622, 35.540300278167706],
              [-80.17750518598848, 35.538376478170676],
              [-80.167418919337365, 35.539021611503017],
              [-80.168903119335027, 35.537486611505358],
              [-80.178933119319538, 35.536498011507035],
              [-80.184029319311662, 35.532419211513286],
              [-80.183243985979516, 35.531091278181975],
              [-80.187304985973242, 35.529440411517839],
              [-80.187640385972657, 35.528386811519624],
              [-80.186235385974896, 35.522547678195224],
              [-80.182758185980276, 35.517442411536535],
              [-80.183507185979124, 35.506999078219508],
              [-80.177953119321046, 35.5001556115634],
              [-80.175848919324324, 35.495246611570906],
              [-80.175141385992049, 35.486727611584229],
              [-80.176232585990419, 35.48542181158632],
              [-80.171724185997448, 35.484530278254226],
              [-80.169119319334811, 35.482378478257715],
              [-80.163715519343214, 35.482311411591013],
              [-80.160326919348392, 35.480342811594198],
              [-80.157553986019423, 35.476564811599928],
              [-80.149154519365823, 35.47480307826936],
              [-80.14571138603776, 35.477070811599162],
              [-80.15024711936411, 35.477802878264754],
              [-80.152094586027943, 35.47690947826618],
              [-80.153466786025774, 35.477848211597916],
              [-80.144339786039836, 35.478445011597046],
              [-80.144059986040304, 35.479704611595025],
              [-80.14285591937562, 35.478216211597498],
              [-80.13885238604837, 35.478926411596376],
              [-80.139944719380082, 35.482430211590895],
              [-80.143052586041961, 35.482590211590605],
              [-80.140448719379322, 35.483621011589037],
              [-80.14210171937674, 35.492506478241978],
              [-80.139272786047798, 35.484560011587519],
              [-80.137872919383256, 35.484354011587868],
              [-80.137620586050275, 35.482178478257879],
              [-80.136052719386043, 35.482247211591186],
              [-80.137368719384085, 35.481079278259642],
              [-80.13577271938658, 35.480117411594563],
              [-80.136472719385438, 35.479315811595711],
              [-80.134344719388764, 35.477163278265664],
              [-80.134484586055123, 35.474919011602481],
              [-80.132664786057944, 35.472720478272549],
              [-80.130817186060938, 35.472377078273041],
              [-80.128969386063773, 35.475651811601495],
              [-80.12714958606665, 35.476407611600223],
              [-80.129277519396624, 35.472033478273772],
              [-80.126674186067362, 35.468552478279037],
              [-80.121467319408737, 35.468620878279012],
              [-80.121019319409413, 35.469811811610498],
              [-80.122390719407349, 35.472193611606656],
              [-80.120347319410484, 35.47095681160863],
              [-80.118191386080412, 35.472628411606081],
              [-80.117016319415711, 35.468231211612931],
              [-80.112900586088756, 35.471139078275087],
              [-80.112060786090069, 35.470841411608831],
              [-80.11203371942338, 35.467612278280626],
              [-80.111724586090588, 35.47226107827322],
              [-80.107750186096666, 35.469397878277732],
              [-80.106965586097942, 35.471413011607979],
              [-80.107469319430493, 35.47246647827302],
              [-80.108897186094964, 35.472008678273653],
              [-80.108196519429384, 35.474710878269548],
              [-80.106937119431223, 35.473359478271732],
              [-80.106991919431209, 35.477115211599084],
              [-80.105312186100491, 35.476748478266359],
              [-80.105593319433353, 35.473405078271639],
              [-80.104081719435726, 35.473198678271956],
              [-80.104558319434943, 35.470679678275815],
              [-80.103411519436804, 35.468251878279546],
              [-80.101872119439179, 35.467679011613825],
              [-80.101929119439092, 35.464747811618395],
              [-80.10092191944068, 35.463510878286968],
              [-80.101901586105782, 35.463511078286786],
              [-80.103386119436834, 35.46092367829084],
              [-80.104813519434572, 35.461450611623377],
              [-80.105401519433656, 35.460534811624882],
              [-80.10509411943417, 35.459092011626979],
              [-80.101455386106522, 35.459045411627244],
              [-80.102911119437579, 35.458519011628084],
              [-80.102883719437614, 35.45689287829714],
              [-80.104254986102092, 35.457420011629722],
              [-80.103415786103426, 35.455793811632304],
              [-80.104647386101533, 35.455656611632492],
              [-80.102856386104349, 35.455038011633349],
              [-80.104591919434995, 35.454374211634388],
              [-80.104229319435547, 35.450710011640126],
              [-80.105739586099787, 35.454099611634945],
              [-80.107111786097676, 35.451420478305693],
              [-80.10655291943192, 35.448489078310217],
              [-80.103587719436518, 35.444526611649678],
              [-80.101544319439654, 35.44594601164755],
              [-80.101599186106284, 35.448556811643471],
              [-80.099501519442867, 35.445808078314371],
              [-80.099639919442666, 35.4496554783085],
              [-80.098324519444702, 35.449334611642314],
              [-80.097512319445968, 35.450777078306658],
              [-80.098070119444969, 35.455403211632813],
              [-80.095718119448691, 35.457417878296383],
              [-80.096081519448205, 35.458540078294732],
              [-80.093674319451907, 35.4587912782942],
              [-80.096973919446725, 35.466280811615889],
              [-80.092189519454166, 35.461195411623919],
              [-80.089976919457627, 35.463851211619669],
              [-80.089917586124443, 35.470080278276669],
              [-80.091148119455852, 35.472714211606046],
              [-80.092072586121049, 35.471134411608375],
              [-80.094452186117394, 35.47124961160813],
              [-80.091930919454626, 35.474706811602857],
              [-80.093301786119184, 35.476699678266414],
              [-80.095737586115376, 35.476379811600282],
              [-80.094644586117056, 35.47853207826364],
              [-80.099264386109894, 35.478189878264118],
              [-80.097359719446217, 35.480204611594331],
              [-80.094979919449884, 35.480318411594112],
              [-80.097162319446511, 35.484051878255116],
              [-80.099346719443133, 35.482770011590389],
              [-80.1006063861077, 35.48345741158937],
              [-80.100802919440866, 35.481487878258974],
              [-80.102537986104721, 35.48570221158576],
              [-80.105617519433395, 35.485954678252142],
              [-80.105198719434043, 35.481305678259332],
              [-80.107381786097278, 35.484420811587881],
              [-80.106793319431574, 35.486504611584621],
              [-80.107661386096879, 35.48586347825227],
              [-80.109144986094577, 35.487123411583582],
              [-80.110265319426105, 35.485795278252283],
              [-80.112112986089869, 35.485910078252175],
              [-80.108389919429101, 35.489436211580085],
              [-80.110098186093069, 35.491749478243094],
              [-80.111330119424508, 35.491177211577224],
              [-80.111722586090593, 35.493971211572898],
              [-80.113262719421527, 35.494429411572241],
              [-80.116962519415779, 35.50188287822732],
              [-80.115226186085067, 35.501218611561796],
              [-80.115534386084676, 35.50014361156326],
              [-80.113930119420502, 35.499307278231299],
              [-80.110654986092243, 35.493719078240019],
              [-80.107463519430553, 35.491084878244067],
              [-80.10239471943828, 35.492641011575074],
              [-80.104857986101251, 35.49527521157097],
              [-80.104548986101634, 35.497908811566845],
              [-80.107357119430617, 35.500147078230043],
              [-80.106851919431449, 35.504561078223105],
              [-80.10578758609978, 35.504652611556423],
              [-80.10967891942704, 35.511592078212288],
              [-80.103687519436335, 35.503461278224847],
              [-80.101474319439831, 35.505453278221808],
              [-80.101192386106902, 35.511590678212372],
              [-80.100435986108096, 35.511407411545974],
              [-80.100295386108257, 35.507170611552453],
              [-80.099006919443582, 35.506643611553272],
              [-80.10093931944067, 35.504010411557431],
              [-80.101611719439575, 35.500735678229205],
              [-80.104244119435521, 35.500827811562317],
              [-80.103376386103548, 35.499397011564554],
              [-80.102088186105505, 35.49960287823086],
              [-80.100716986107614, 35.495846678236603],
              [-80.098448719444491, 35.495250811570941],
              [-80.099345186109758, 35.493556411573707],
              [-80.097637119445722, 35.492090211575885],
              [-80.091559786121877, 35.493416678240578],
              [-80.092568119453631, 35.492638411575058],
              [-80.091196519455764, 35.489798278246155],
              [-80.093688519451916, 35.49167687824314],
              [-80.096461319447542, 35.489731211579624],
              [-80.095005319449854, 35.487921611582294],
              [-80.09304531945287, 35.487554478249535],
              [-80.09391371945145, 35.486501278251183],
              [-80.093325919452468, 35.485447678252854],
              [-80.092429586120545, 35.486134478251756],
              [-80.09259838612013, 35.484325411587974],
              [-80.090525919456695, 35.48537807825295],
              [-80.092515519453627, 35.481737611591996],
              [-80.092039919454407, 35.480890078259961],
              [-80.08901591945903, 35.481530411592303],
              [-80.089800519457867, 35.480133678261154],
              [-80.088820719459477, 35.479492078262069],
              [-80.087000319462277, 35.480705211593659],
              [-80.084480586132884, 35.480521078260495],
              [-80.085488919464638, 35.479559678261978],
              [-80.086328519463336, 35.480155278261009],
              [-80.089129919458969, 35.477133411599198],
              [-80.086330519463331, 35.476674411599788],
              [-80.087143719462063, 35.4743616782701],
              [-80.085101586131884, 35.471590011607702],
              [-80.081910519470114, 35.471176611608314],
              [-80.082217586136323, 35.472779678272616],
              [-80.077875519476436, 35.476854278266217],
              [-80.071433919486481, 35.480194678261057],
              [-80.070426186154691, 35.479965278261432],
              [-80.071352119486562, 35.477240478265685],
              [-80.067656586158932, 35.476986811599318],
              [-80.063649786165172, 35.481015078259816],
              [-80.065916319494988, 35.482894211590178],
              [-80.064124319497807, 35.48287041159017],
              [-80.062557719500148, 35.48140387825913],
              [-80.065894319495044, 35.475474411601738],
              [-80.069982586155277, 35.474262811603637],
              [-80.072669186151188, 35.475661078268047],
              [-80.075330186147085, 35.473647078271199],
              [-80.076536786145198, 35.469639878277349],
              [-80.077823319476465, 35.471358078274704],
              [-80.078692319475181, 35.469595078277564],
              [-80.078693119475133, 35.468633211612314],
              [-80.076313919478764, 35.468174211613018],
              [-80.075000186147577, 35.465219411617625],
              [-80.071192586153416, 35.466064878282964],
              [-80.071894986152415, 35.462698878288165],
              [-80.070244386154911, 35.461484278290015],
              [-80.072709719484465, 35.45869147829444],
              [-80.069970586155364, 35.453514678302383],
              [-80.07461371948142, 35.457684878295879],
              [-80.076540986145119, 35.463594211620091],
              [-80.080742319471938, 35.459450878293183],
              [-80.081276386137802, 35.455764078298955],
              [-80.08007358613969, 35.454733011633891],
              [-80.082789186135471, 35.453634878302239],
              [-80.082650719469029, 35.451321878305862],
              [-80.079850319473394, 35.453748211635343],
              [-80.077779119476531, 35.45381601163524],
              [-80.078732186141679, 35.451503411638953],
              [-80.077866186143126, 35.449190078309073],
              [-80.07957351947374, 35.448938878309605],
              [-80.079574919473771, 35.446923678312714],
              [-80.077783319476509, 35.447403811645245],
              [-80.077392786143832, 35.445617411648072],
              [-80.074229319482129, 35.447196078312174],
              [-80.074566586148194, 35.445203811648696],
              [-80.072691786151154, 35.444813678315938],
              [-80.074821786147822, 35.440509278322679],
              [-80.073761386149499, 35.436249278329228],
              [-80.07560638614666, 35.438860811658571],
              [-80.076107786145769, 35.442319011653183],
              [-80.07717098614421, 35.442640078319414],
              [-80.080054919473071, 35.440328278322795],
              [-80.079245186140952, 35.437305211660941],
              [-80.081259319471201, 35.438519678325747],
              [-80.08403258613356, 35.433391011666913],
              [-80.084087319466789, 35.435497878330466],
              [-80.086550319462958, 35.43460561166512],
              [-80.08484111946558, 35.438383611659219],
              [-80.084671986132548, 35.440559211655909],
              [-80.086266586130023, 35.441498811654412],
              [-80.085089719465202, 35.44413187831708],
              [-80.087131186128715, 35.446674611646415],
              [-80.088055519460568, 35.44514061164881],
              [-80.09026671945719, 35.444545878316319],
              [-80.092144119454247, 35.439920478323529],
              [-80.08610078613026, 35.43765127832711],
              [-80.09200678612109, 35.434241011665677],
              [-80.094639386116967, 35.429386811673112],
              [-80.091397986122161, 35.420500278353643],
              [-80.094923719449923, 35.41910427835586],
              [-80.099397386109729, 35.42547201167929],
              [-80.103904119435981, 35.419587411688497],
              [-80.103149319437193, 35.417411678358462],
              [-80.104604719434974, 35.415786011694422],
              [-80.103794519436235, 35.412579678366001],
              [-80.106451119432108, 35.415442811694845],
              [-80.110843986091879, 35.412718411699075],
              [-80.114061919420237, 35.408161478372733],
              [-80.114396519419756, 35.413520411697789],
              [-80.115459386084751, 35.414413678363189],
              [-80.114004386087004, 35.415466878361542],
              [-80.114339719419775, 35.417917478357708],
              [-80.111905119423568, 35.4204820783537],
              [-80.112323986089564, 35.423390478349233],
              [-80.110253786092869, 35.42306961168299],
              [-80.109749986093561, 35.423962678348403],
              [-80.11011351942642, 35.425084878346581],
              [-80.111904119423571, 35.424810278346968],
              [-80.109469119427388, 35.42799307834207],
              [-80.109860186093442, 35.430031478338947],
              [-80.111454919424261, 35.43115387833717],
              [-80.107229919430836, 35.430786678337654],
              [-80.104123119435712, 35.433648811666615],
              [-80.105885586099589, 35.434840011664789],
              [-80.101855386105797, 35.43685441166167],
              [-80.10272231943776, 35.43875541165869],
              [-80.103477519436638, 35.439625811657379],
              [-80.107087519431047, 35.439305878324546],
              [-80.108682986095175, 35.437680211660336],
              [-80.108626386095352, 35.440268011656315],
              [-80.111425119424382, 35.438527878325715],
              [-80.112263719423083, 35.442054878320221],
              [-80.115594386084467, 35.439742211657233],
              [-80.120547119410219, 35.442788611652418],
              [-80.123036919406275, 35.451857611638275],
              [-80.121272786075735, 35.457124678296793],
              [-80.12247598607388, 35.460857611624419],
              [-80.126842586067085, 35.461521878289886],
              [-80.131041186060543, 35.464041078286073],
              [-80.138543786048956, 35.471254678274875],
              [-80.142463186042846, 35.472582478272841],
              [-80.144338719373252, 35.471208211608257],
              [-80.144618919372817, 35.472925811605592],
              [-80.150973119362902, 35.469650011610781],
              [-80.149853786031372, 35.47203187827364],
              [-80.152457319360565, 35.471619211607617],
              [-80.160856719347635, 35.473999078270651],
              [-80.164300119342215, 35.473860811604084],
              [-80.165446786007124, 35.471203878274935],
              [-80.165027519341095, 35.47255521160622],
              [-80.166203519339319, 35.473173211605285],
              [-80.165391719340619, 35.473379611604969],
              [-80.165533586007086, 35.477959678264426],
              [-80.176651919323149, 35.48411627825493],
              [-80.181524385982186, 35.485145011586724],
              [-80.179817719318123, 35.487115211583614],
              [-80.182001585981368, 35.487068411583664],
              [-80.182896519313431, 35.485258878253205],
              [-80.186283719308051, 35.484043611588277],
              [-80.186872585973902, 35.485440278252952],
              [-80.18354131931244, 35.486243411585065],
              [-80.183766919312063, 35.488212811581889],
              [-80.182871185980048, 35.488556611581373],
              [-80.183935519311831, 35.488968411580629],
              [-80.181136185982837, 35.489656678246376],
              [-80.180885519316575, 35.491236878243853],
              [-80.1821185859813, 35.492060811575925],
              [-80.182204785981128, 35.494144811572653],
              [-80.185284719309664, 35.493754078240045],
              [-80.185733985975673, 35.494876078238178],
              [-80.190239785968686, 35.492721278241618],
              [-80.188172185971894, 35.496592611568985],
              [-80.183803919312027, 35.497167078234668],
              [-80.184843385977047, 35.500860811562234],
              [-80.187080519306903, 35.49716561156805],
              [-80.187280119306649, 35.501707011560995],
              [-80.19377771929652, 35.501428678228081],
              [-80.186498985974481, 35.503493678224856],
              [-80.185326319309638, 35.504868411556117],
              [-80.186729919307481, 35.509287678215799],
              [-80.185977185975275, 35.514166011541704],
              [-80.187771919305874, 35.516844478204177],
              [-80.18687598597387, 35.517509078203034],
              [-80.187353719306543, 35.519455478200143],
              [-80.191023185967424, 35.519499411533388],
              [-80.190491785968334, 35.520553078198418],
              [-80.192230385965615, 35.522865211528199],
              [-80.193602919296779, 35.522864411528133],
              [-80.194221585962509, 35.525635011523775],
              [-80.197358385957671, 35.524854611525086],
              [-80.197103185958042, 35.521007278197601],
              [-80.19964978595408, 35.518326411535099],
              [-80.199312519287901, 35.516929678203951],
              [-80.200487585952771, 35.515463211539611],
              [-80.205358719278536, 35.513307411542939],
              [-80.211571185935554, 35.507096811552572],
              [-80.216050385928611, 35.50526147822211],
              [-80.215433719262876, 35.504780878222846],
              [-80.216245319261702, 35.50432227822364],
              [-80.212407785934261, 35.503638078224583],
              [-80.218901519257486, 35.501068278228672],
              [-80.21856471925804, 35.500152011563443],
              [-80.220884985921145, 35.500151411563422],
              [-80.220970919254285, 35.502120211560396],
              [-80.217476719259764, 35.503428278224987],
              [-80.218734719257725, 35.504480811556732],
              [-80.217448385926332, 35.505329078222019],
              [-80.217646919259494, 35.507962611551193],
              [-80.216387119261469, 35.507849078218044],
              [-80.214849519263851, 35.509888478214862],
              [-80.212832319266909, 35.507783011551624],
              [-80.210203385937689, 35.511586478212337],
              [-80.206843385942818, 35.513260611542989],
              [-80.205642919278148, 35.516902678204019],
              [-80.209115785939332, 35.5166714115378],
              [-80.209509785938792, 35.518274078201898],
              [-80.201302385951522, 35.518279478201976],
              [-80.199626519287449, 35.5236624115268],
              [-80.207222185942271, 35.527756811520476],
              [-80.201339185951383, 35.527875211520325],
              [-80.202769785949215, 35.529798011517414],
              [-80.200219585953164, 35.528883478185492],
              [-80.198963185955165, 35.533808011511155],
              [-80.201008785952013, 35.534196211510562],
              [-80.198628385955601, 35.535274011508818],
              [-80.198741985955508, 35.53701441150605],
              [-80.199694785953966, 35.537151211505829],
              [-80.198884319288538, 35.539441811502456],
              [-80.200062519286803, 35.541089878166588],
              [-80.199195919288115, 35.543334811496322],
              [-80.20219671928345, 35.546218478158551],
              [-80.209398785938959, 35.546900878157487],
              [-80.208864985939726, 35.545458411493144],
              [-80.210122585937825, 35.542136878164911],
              [-80.211272185936025, 35.542914678163754],
              [-80.214128985931609, 35.541836278165249],
              [-80.218333785924983, 35.543459211496099],
              [-80.219789919256129, 35.542519078164219],
              [-80.21967991925635, 35.544397078161353],
              [-80.218027385925552, 35.545039611493621],
              [-80.212254719267889, 35.544608678161069],
              [-80.212761185933743, 35.546692411491108],
              [-80.210548585937204, 35.547678678156274],
              [-80.212821985933601, 35.551112211484337],
              [-80.215008785930252, 35.552049611482857],
              [-80.213047719266626, 35.552486211482176],
              [-80.213749585932192, 35.553699411480238],
              [-80.212552785934065, 35.56166987813458],
              [-80.210594385936986, 35.56499187812949],
              [-80.210205985937705, 35.56881667812354],
              [-80.210350919270695, 35.573419811449639],
              [-80.213295185932907, 35.574562678114546],
              [-80.210240185937607, 35.574908411447382],
              [-80.209823385938307, 35.578504078108494],
              [-80.213276185932841, 35.582944478101467],
              [-80.224272519249212, 35.588798811425704],
              [-80.223656185916809, 35.589234478091839],
              [-80.231934585904014, 35.595181878082599],
              [-80.234714185899691, 35.598133678078],
              [-80.237919519228058, 35.604566078068046],
              [-80.239350319225821, 35.605045611400556],
              [-80.239183719225991, 35.60625947806534],
              [-80.241567785889004, 35.606646678064692],
              [-80.243470719219317, 35.60382801140247],
              [-80.243978519218615, 35.605865678065982],
              [-80.245492119216294, 35.605520678066569],
              [-80.25019591920892, 35.601298278072989],
              [-80.253141985871082, 35.600087678074999],
              [-80.249640385876432, 35.604234211401831],
              [-80.251677585873324, 35.604116811402037],
              [-80.256617319198995, 35.601190411406492],
              [-80.256506985865826, 35.600022411408418],
              [-80.258132719196624, 35.600436078074324],
              [-80.26708478584942, 35.595062811416028],
              [-80.266973985849631, 35.594169611417442],
              [-80.27061991917725, 35.593417211418625],
              [-80.271011519176682, 35.594104478084262],
              [-80.269160985846213, 35.5941028780843],
              [-80.264949385852674, 35.598267011411167],
              [-80.263632185854817, 35.59789927807833],
              [-80.258269985863024, 35.6023370780714],
              [-80.25837958586294, 35.604054811402079],
              [-80.257003319198418, 35.605587878066444],
              [-80.254509185868926, 35.604531878068087],
              [-80.255403519200854, 35.606479278065081],
              [-80.253664585870183, 35.60670661139801],
              [-80.254756385868518, 35.607829878062944],
              [-80.250742719208063, 35.610138678059286],
              [-80.252171719205876, 35.610987611391295],
              [-80.253239985870778, 35.609271078060715],
              [-80.255006985868192, 35.609089678060968],
              [-80.255481585867415, 35.610487211392126],
              [-80.259241319194928, 35.609231278060747],
              [-80.257870119197037, 35.607306211397031],
              [-80.258516585862651, 35.606253478065412],
              [-80.260055185860267, 35.60875121139469],
              [-80.26128891919177, 35.608844011394751],
              [-80.261487119191372, 35.607584611396589],
              [-80.263029585855747, 35.607494411396772],
              [-80.262274519190214, 35.606096811398913],
              [-80.268361785847333, 35.604499278068033],
              [-80.258756785862374, 35.614360678052833],
              [-80.260830919192415, 35.615255811384714],
              [-80.266049119184345, 35.614024078053376],
              [-80.267509785848688, 35.612284878056016],
              [-80.270090985844661, 35.611508611390491],
              [-80.268320719180736, 35.614026211386715],
              [-80.268907119179858, 35.615950411383551],
              [-80.271177585843077, 35.616845478048958],
              [-80.269156919179466, 35.617874278047395],
              [-80.269183185846089, 35.619065211378768],
              [-80.270556785844008, 35.619593211378003],
              [-80.268929385846491, 35.62016421137713],
              [-80.269516785845553, 35.621309811375284],
              [-80.268141385847741, 35.622155878040701],
              [-80.266349585850548, 35.619749678044286],
              [-80.264554719186663, 35.619725078044382],
              [-80.264580119186633, 35.621557211374977],
              [-80.263233585855403, 35.621693411374679],
              [-80.265221785852304, 35.623893678037973],
              [-80.269402385845751, 35.623004278039332],
              [-80.270213385844556, 35.624745611370031],
              [-80.271787119175428, 35.622525611373362],
              [-80.274086519171874, 35.62289401137275],
              [-80.277005319167358, 35.621545278041708],
              [-80.277681185832989, 35.619232811378595],
              [-80.278100385832317, 35.620469811376665],
              [-80.279811119162957, 35.620631478043038],
              [-80.280909519161298, 35.616853678048926],
              [-80.282340985825726, 35.616007478050165],
              [-80.282194185825915, 35.621343278041877],
              [-80.280370385828746, 35.622051811374149],
              [-80.281265919160774, 35.623747278038081],
              [-80.277339719166775, 35.623171478039126],
              [-80.276579119167991, 35.625827478034864],
              [-80.274867385837354, 35.626352678034095],
              [-80.28027858582891, 35.628280878031092],
              [-80.284321519156038, 35.625146611369246],
              [-80.284716985822001, 35.622673611373216],
              [-80.288499985816088, 35.625836811368288],
              [-80.292092119143945, 35.624121811370969],
              [-80.292403185810144, 35.621740211374629],
              [-80.294674385806559, 35.622406078040285],
              [-80.296697385803441, 35.618926411378993],
              [-80.294903319139564, 35.617963278047114],
              [-80.29711938580283, 35.617690011380944],
              [-80.296419719137248, 35.616200878049995],
              [-80.298969719133197, 35.618469811379725],
              [-80.297766719135154, 35.615377278051142],
              [-80.299896585798479, 35.616981811382061],
              [-80.300179585797991, 35.614302611386165],
              [-80.29762938580194, 35.612400078055771],
              [-80.301500785796009, 35.611143011391164],
              [-80.299621785798934, 35.611256278057624],
              [-80.298697185800336, 35.610316811392465],
              [-80.303661519125967, 35.609678611393463],
              [-80.29973718579862, 35.607867011396138],
              [-80.306186185788647, 35.608878611394573],
              [-80.306664719121329, 35.60684067806443],
              [-80.303834785792333, 35.604228278068547],
              [-80.308321185785417, 35.604643078067852],
              [-80.308547919118325, 35.601895011405475],
              [-80.311467319113888, 35.598003411411526],
              [-80.313486185777322, 35.598004611411454],
              [-80.311492919113846, 35.600980611406897],
              [-80.314549985775784, 35.60004327807502],
              [-80.318446585769664, 35.601442211406152],
              [-80.318868119102376, 35.600320211407848],
              [-80.320270119100144, 35.600275078074674],
              [-80.320467585766551, 35.59874081141038],
              [-80.324084185760967, 35.599360811409269],
              [-80.324982519092771, 35.597872611411731],
              [-80.328150385754554, 35.59872121141035],
              [-80.329693519085538, 35.597301878079122],
              [-80.329328519086175, 35.598217811411189],
              [-80.335132585743679, 35.598426078077466],
              [-80.338750785738114, 35.595999678081341],
              [-80.341107585734449, 35.59279427808633],
              [-80.344416719062679, 35.591489678088351],
              [-80.344136519063113, 35.59080261142276],
              [-80.349548319054747, 35.588949011425484],
              [-80.351343719051954, 35.58581181143046],
              [-80.353782785714884, 35.585835211430435],
              [-80.352016185717503, 35.586819611428893],
              [-80.353025185715978, 35.587919078093819],
              [-80.355128185712715, 35.586843011428698],
              [-80.356361585710829, 35.587553278094447],
              [-80.35109051905232, 35.58858287809278],
              [-80.353471919048616, 35.594331611417203],
              [-80.351845585717854, 35.594422878083776],
              [-80.349435585721608, 35.590735211422839],
              [-80.345032385728416, 35.593894611417795],
              [-80.344751385728784, 35.595451811415387],
              [-80.342339719065819, 35.596344278080721],
              [-80.34264771906544, 35.597466611412415],
              [-80.340264185735805, 35.597420078079153],
              [-80.339422319070366, 35.599000011409998],
              [-80.335159185743748, 35.601243011406552],
              [-80.333895919079055, 35.603670078069399],
              [-80.336166319075517, 35.605548811399728],
              [-80.334118985745363, 35.606051878065557],
              [-80.332970119080414, 35.604402611401554],
              [-80.331511785749342, 35.604837211400934],
              [-80.331904985748736, 35.603669411402677],
              [-80.329830785751938, 35.602225878071579],
              [-80.32105178576569, 35.60529087806691],
              [-80.321134385765504, 35.60735201139687],
              [-80.32351551909511, 35.610994411391289],
              [-80.321916785764301, 35.611222678057686],
              [-80.318975319102151, 35.607190678063887],
              [-80.315299719107827, 35.60952487806037],
              [-80.306914919120914, 35.609176811394207],
              [-80.308006785785892, 35.611375878057402],
              [-80.304527385791289, 35.613549478054097],
              [-80.307555585786588, 35.614307011386131],
              [-80.306544719121518, 35.615611811384156],
              [-80.308394119118589, 35.617445011381278],
              [-80.304385519124878, 35.615244078051489],
              [-80.305392919123221, 35.617855478047261],
              [-80.303541585792857, 35.618060478047028],
              [-80.30222171912817, 35.619983278044117],
              [-80.305417719123227, 35.621451011375086],
              [-80.301379319129524, 35.620921678042521],
              [-80.299639319132211, 35.621905411374485],
              [-80.306143385788744, 35.625298811369078],
              [-80.301712585795599, 35.624380078037177],
              [-80.301178519129792, 35.625616411368696],
              [-80.299188185799551, 35.624401411370513],
              [-80.296687185803478, 35.629003011363466],
              [-80.301564585795916, 35.632510011358022],
              [-80.301843519128681, 35.634067478022132],
              [-80.295171185805827, 35.630192678028209],
              [-80.294160919140666, 35.630650078027486],
              [-80.294242785807285, 35.632734211357558],
              [-80.29701818580287, 35.6344536113549],
              [-80.297969119134791, 35.637316878017032],
              [-80.299260519132815, 35.636310011352009],
              [-80.300297785797852, 35.637066478017459],
              [-80.300493185797563, 35.638005611349456],
              [-80.298191785801123, 35.63905761134771],
              [-80.299451519132447, 35.641806611343497],
              [-80.300462185797585, 35.641074278011388],
              [-80.300403319131021, 35.643914078006901],
              [-80.302000985795246, 35.645518078004443],
              [-80.305201119123581, 35.643665078007245],
              [-80.303261985793256, 35.647007478002081],
              [-80.305027985790502, 35.648749011332768],
              [-80.30889971911779, 35.649140611332143],
              [-80.30912618578418, 35.646804878002399],
              [-80.31058418578192, 35.647882011334048],
              [-80.31179131911324, 35.647012478001955],
              [-80.311091185781038, 35.645477678004454],
              [-80.313783519110302, 35.646738678002578],
              [-80.31339278577741, 35.64431101133971],
              [-80.315075585774878, 35.645044611338562],
              [-80.317320519104783, 35.644358678006199],
              [-80.316648319105809, 35.642915611341834],
              [-80.319314519101624, 35.641474211344075],
              [-80.318586185769504, 35.640214278012706],
              [-80.319148385768585, 35.638634411348391],
              [-80.321025185765677, 35.642391011342681],
              [-80.323045919095875, 35.641590478010585],
              [-80.32178238576455, 35.642918078008506],
              [-80.318667985769366, 35.643305878007936],
              [-80.318637385769364, 35.646741078002606],
              [-80.315916319106975, 35.646213078003314],
              [-80.317654119104247, 35.648435277999965],
              [-80.314819919108629, 35.648983611332312],
              [-80.316361719106283, 35.650702011329713],
              [-80.318241585770011, 35.650702877996252],
              [-80.318044385770349, 35.651824877994613],
              [-80.321269585765322, 35.653933277991428],
              [-80.324693985760007, 35.652102877994139],
              [-80.323794719094678, 35.654163611324293],
              [-80.325814319091592, 35.655172211322622],
              [-80.327218119089366, 35.653890211324722],
              [-80.328926985753355, 35.658242211317997],
              [-80.33058258575079, 35.658219877984777],
              [-80.329853919085338, 35.656868477986848],
              [-80.33061198575075, 35.655884011321689],
              [-80.332940785747155, 35.656297077987688],
              [-80.333475519079684, 35.653617877991792],
              [-80.334708185744432, 35.657053477986494],
              [-80.33703691907408, 35.657604011319052],
              [-80.335857719075989, 35.65879441131716],
              [-80.337653985739848, 35.658085077984992],
              [-80.339872519069786, 35.65444447799058],
              [-80.339480719070309, 35.652291611327314],
              [-80.341332785734153, 35.651696811328179],
              [-80.342401585732546, 35.646086211336808],
              [-80.344591519062419, 35.642789211342006],
              [-80.344308385729562, 35.648583077999604],
              [-80.342623985732189, 35.650872677996119],
              [-80.343577585730714, 35.651605811328295],
              [-80.340768585735077, 35.658200611318136],
              [-80.345707985727302, 35.656667677987116],
              [-80.345680185727417, 35.655797277988427],
              [-80.350562319053211, 35.656852011320211],
              [-80.350057985720582, 35.654744877990026],
              [-80.351152719052209, 35.653920811324781],
              [-80.351628785718049, 35.656233877987802],
              [-80.35536058571239, 35.656875877986749],
              [-80.356623585710395, 35.655937211321657],
              [-80.356819585710127, 35.65756321131903],
              [-80.359401519039466, 35.656097877987918],
              [-80.359823119038822, 35.652754411326498],
              [-80.366529185695015, 35.65305287799265],
              [-80.362712919034209, 35.654449411323924],
              [-80.362123119035232, 35.656945611319998],
              [-80.36029891903803, 35.658525477984313],
              [-80.353396119048739, 35.657745677985361],
              [-80.35196411905099, 35.660585277980999],
              [-80.344303985729539, 35.658957277983632],
              [-80.344724119062107, 35.660697877980908],
              [-80.349325385721784, 35.662806077977564],
              [-80.352440385716875, 35.662600611311291],
              [-80.358753919040396, 35.664823277974449],
              [-80.359792385705532, 35.664434077975102],
              [-80.360382519037955, 35.660701211314176],
              [-80.365405519030162, 35.661457611312983],
              [-80.367482519026851, 35.658205811317998],
              [-80.367987385692743, 35.659373811316243],
              [-80.365601585696425, 35.662694277977721],
              [-80.361167919036689, 35.66237321131166],
              [-80.362093319035239, 35.665373411306916],
              [-80.37025931902258, 35.670114677966239],
              [-80.370651785688665, 35.672748411295402],
              [-80.37435651901626, 35.672611211295589],
              [-80.374103785683303, 35.67474101129244],
              [-80.378173585676961, 35.673962277960186],
              [-80.38005398567401, 35.676344077956571],
              [-80.38081171900626, 35.675794477957425],
              [-80.38490978566648, 35.677969877954126],
              [-80.381120585672363, 35.677695277954456],
              [-80.38286078566972, 35.678657077953062],
              [-80.384124119001001, 35.681451011282036],
              [-80.381990785671007, 35.682527411280319],
              [-80.379801385674398, 35.67868001128636],
              [-80.37654558567948, 35.67650441128967],
              [-80.371718119020329, 35.676092077957037],
              [-80.367873185692986, 35.673458211294303],
              [-80.367508185693509, 35.675129877958398],
              [-80.36658198569495, 35.674763411292304],
              [-80.366554319028296, 35.672473277962581],
              [-80.365207385697033, 35.670938677964955],
              [-80.362035919035407, 35.671831477963508],
              [-80.363692119032805, 35.669702011300217],
              [-80.360717919037427, 35.666838877971202],
              [-80.359006119040032, 35.666289011305594],
              [-80.35788311904173, 35.668372811302334],
              [-80.357293985709305, 35.66699861130445],
              [-80.353673919048276, 35.666631611304979],
              [-80.353225719049021, 35.664020677975714],
              [-80.351120185718969, 35.666539411305166],
              [-80.349549185721457, 35.664958811307599],
              [-80.348454185723142, 35.666653277971477],
              [-80.345900519060422, 35.666080077972367],
              [-80.346123119059996, 35.670935277964986],
              [-80.343824385730272, 35.665003211307578],
              [-80.342814185731868, 35.664613611308027],
              [-80.341128785734497, 35.667910877969689],
              [-80.341046319067971, 35.664475611308319],
              [-80.337567119073356, 35.663146277977091],
              [-80.33588238574265, 35.665115211307409],
              [-80.336276519075284, 35.662619077977865],
              [-80.332574119081073, 35.65961767798251],
              [-80.332292319081546, 35.661770277979258],
              [-80.32808198575475, 35.663440477976565],
              [-80.327322985755927, 35.665180611307164],
              [-80.327351919089153, 35.66415001130872],
              [-80.325752385758392, 35.663828811309315],
              [-80.32754938575556, 35.662295077978399],
              [-80.326259585757555, 35.660737277980786],
              [-80.324547585760229, 35.661240411313315],
              [-80.325446585758755, 35.659523211316014],
              [-80.323538719095154, 35.659201811316393],
              [-80.322414719096855, 35.661628677979422],
              [-80.321574385764848, 35.659246677983049],
              [-80.317478119104521, 35.658511811317567],
              [-80.317395319104662, 35.656588077987237],
              [-80.315235785774632, 35.655144211322806],
              [-80.315489719107575, 35.653403811325518],
              [-80.31136471911401, 35.653928277991383],
              [-80.314422585775901, 35.654502477990548],
              [-80.312205119112662, 35.655463277989043],
              [-80.31284938577835, 35.656906411320108],
              [-80.311755119113343, 35.656837011320249],
              [-80.31155718578043, 35.658623211317376],
              [-80.312679185778563, 35.659036011316743],
              [-80.311919985779809, 35.660959411313854],
              [-80.313716185776968, 35.660639677980896],
              [-80.31433278577606, 35.661624677979432],
              [-80.312365985779138, 35.664578011308095],
              [-80.312673785778657, 35.665540011306689],
              [-80.313880785776746, 35.665265811307108],
              [-80.313795785776904, 35.666319211305449],
              [-80.315368119107802, 35.665426877973573],
              [-80.314467185775868, 35.668999077967896],
              [-80.316235985773119, 35.667969411302863],
              [-80.317244719104906, 35.670053877966325],
              [-80.319911585767443, 35.668978877967959],
              [-80.320471585766541, 35.670879877965035],
              [-80.325411119092166, 35.670790611298457],
              [-80.325073719092757, 35.671637677963815],
              [-80.329086119086526, 35.673128077961508],
              [-80.329816585751985, 35.672006211296718],
              [-80.330684985750736, 35.674525677959366],
              [-80.333968119078804, 35.675809277957399],
              [-80.333406119079825, 35.676977077955655],
              [-80.336239185742045, 35.680207211283914],
              [-80.336518519074957, 35.682474477947039],
              [-80.338034719072596, 35.681948277947924],
              [-80.338176185739087, 35.679635277951377],
              [-80.340448785735532, 35.681582611281726],
              [-80.340083519069481, 35.68245281128037],
              [-80.345022985728463, 35.683965811277972],
              [-80.343029385731541, 35.685179011276261],
              [-80.338061719072527, 35.683872011278254],
              [-80.337723985739785, 35.685795677941883],
              [-80.33935018573726, 35.68936881126956],
              [-80.345019185728404, 35.692805611264419],
              [-80.344204719062986, 35.69365267792972],
              [-80.345803519060553, 35.697042477924413],
              [-80.338731719071575, 35.691292277933371],
              [-80.338841919071285, 35.695277011260544],
              [-80.3360883857423, 35.699535677920551],
              [-80.337182785740652, 35.700612411252223],
              [-80.335105119077127, 35.7009094112517],
              [-80.335159519077081, 35.703955277913735],
              [-80.333812785745863, 35.702122677916634],
              [-80.332070985748544, 35.704091477913437],
              [-80.338071919072604, 35.71849847789116],
              [-80.342088119066318, 35.71742341122615],
              [-80.342425985732405, 35.715408211229203],
              [-80.343155119064704, 35.717767277892335],
              [-80.345880119060325, 35.715226011229618],
              [-80.348351385723277, 35.714562477897175],
              [-80.351102385719003, 35.716899077893686],
              [-80.352197585717306, 35.716784877893815],
              [-80.352029785717548, 35.714952811229921],
              [-80.353320985715584, 35.716235477894713],
              [-80.355988785711418, 35.716281811227987],
              [-80.356606985710471, 35.714747611230337],
              [-80.354670119046773, 35.712983677899729],
              [-80.358769519040379, 35.713511211232117],
              [-80.36008991903833, 35.711335877902343],
              [-80.360791585703907, 35.712595477900322],
              [-80.364245385698609, 35.712573077900402],
              [-80.363627119032913, 35.714496811230731],
              [-80.364974919030828, 35.71561901122891],
              [-80.367193519027353, 35.714359677897619],
              [-80.377863985677436, 35.714016677897973],
              [-80.380924785672676, 35.710948011236269],
              [-80.380194719007193, 35.713352611232324],
              [-80.384687519000181, 35.712573811233653],
              [-80.392466518988158, 35.71731381122629],
              [-80.39078191899074, 35.719306277889814],
              [-80.391961385655577, 35.719924477888924],
              [-80.393533718986475, 35.718664677890899],
              [-80.3943767189852, 35.721069411220526],
              [-80.392186118988491, 35.720680211221008],
              [-80.395893785649491, 35.7244126112152],
              [-80.399291785644209, 35.724045611215786],
              [-80.405104185635196, 35.720746811220977],
              [-80.405328585634834, 35.719464277889529],
              [-80.406676718966025, 35.720288411221588],
              [-80.40720998563188, 35.719097477890273],
              [-80.408165185630367, 35.720356811221563],
              [-80.410018118960863, 35.719028077890243],
              [-80.411057385625952, 35.719646077889308],
              [-80.40934458562856, 35.720264877888269],
              [-80.413220318955894, 35.720905077887437],
              [-80.414511718953861, 35.720057277888714],
              [-80.416028918951497, 35.721568411219721],
              [-80.409260718962059, 35.721158077887026],
              [-80.403616185637475, 35.721983811219047],
              [-80.41358578562199, 35.721889811219285],
              [-80.422995118940776, 35.724634811214969],
              [-80.422936985607521, 35.720856077887447],
              [-80.419987985612067, 35.720376211221605],
              [-80.423216785607053, 35.719023877890208],
              [-80.421894585609152, 35.71481047789689],
              [-80.423354318940142, 35.71366501123191],
              [-80.427481985600423, 35.713594611232111],
              [-80.427258785600714, 35.715953477895027],
              [-80.429729785596976, 35.715677677895428],
              [-80.428944518931416, 35.717189611226502],
              [-80.426332518935567, 35.716732477893913],
              [-80.424873318937841, 35.718542277891061],
              [-80.424172185605471, 35.720191611221765],
              [-80.425071385604213, 35.721084277887144],
              [-80.423891785605974, 35.720993211220616],
              [-80.423696318939676, 35.722894011217647],
              [-80.425971585602724, 35.723855011216131],
              [-80.424707718938066, 35.723603477883159],
              [-80.425859785602995, 35.724748211214717],
              [-80.424202385605554, 35.724038877882606],
              [-80.423978385605778, 35.725115411214233],
              [-80.430159385596255, 35.728891611208326],
              [-80.442577318910367, 35.734038477867045],
              [-80.442072718911163, 35.735298411198414],
              [-80.417603118949046, 35.72477401121472],
              [-80.409008518962423, 35.722852877884293],
              [-80.403476185637714, 35.72354107788334],
              [-80.397186118980756, 35.726038477879456],
              [-80.391456585656385, 35.724504811215013],
              [-80.395585518983239, 35.727160811210979],
              [-80.404319585636358, 35.725854011212959],
              [-80.391316918989844, 35.729497211207445],
              [-80.390305585658098, 35.727986011209794],
              [-80.391034985657029, 35.722787277884549],
              [-80.388535318994172, 35.720359877888313],
              [-80.374774985682279, 35.71813887789159],
              [-80.378200985676926, 35.719994011222127],
              [-80.385502518998862, 35.721436477886641],
              [-80.384884718999899, 35.722077811218867],
              [-80.377498919011316, 35.720177211221824],
              [-80.373427119017606, 35.718459411224444],
              [-80.371011919021441, 35.718367677891365],
              [-80.37115218568789, 35.719833277888995],
              [-80.370000785689683, 35.719993611222094],
              [-80.367950919026157, 35.719260611223262],
              [-80.369720185690085, 35.71857381122436],
              [-80.368063319025964, 35.718504877891178],
              [-80.361772385702466, 35.721527077886492],
              [-80.361407585702921, 35.71992401122219],
              [-80.360845719037172, 35.720840011220901],
              [-80.357756719042015, 35.720702077887665],
              [-80.358402119040989, 35.722763277884496],
              [-80.351520719051678, 35.725464211213591],
              [-80.350566385719844, 35.723815077882875],
              [-80.345033119061725, 35.725508411213525],
              [-80.338854785738022, 35.725117211214183],
              [-80.339584519070172, 35.726147877879271],
              [-80.344190185729701, 35.726332611212229],
              [-80.342785519065217, 35.727408477877361],
              [-80.337955385739292, 35.726536677878585],
              [-80.337169919073972, 35.725024877881026],
              [-80.332115519081754, 35.723786477882811],
              [-80.334106919078749, 35.727955211209746],
              [-80.339836319069718, 35.728163277876206],
              [-80.342699785732066, 35.730775011205367],
              [-80.340564585735365, 35.731873477870352],
              [-80.3360433857423, 35.730635411205697],
              [-80.335115719077123, 35.732238077869795],
              [-80.331100919083326, 35.729648811207198],
              [-80.331550985749288, 35.728412277875691],
              [-80.329839185752007, 35.725938277879493],
              [-80.326663519090289, 35.729303411207752],
              [-80.328010919088172, 35.730632211205602],
              [-80.326127785757762, 35.732348877869583],
              [-80.326265385757495, 35.736677277863009],
              [-80.328172719087945, 35.740800277856522],
              [-80.325895319091444, 35.743845211185089],
              [-80.325109319092689, 35.743157677852764],
              [-80.32544898575884, 35.739402077858756],
              [-80.321995185764138, 35.737820411194491],
              [-80.324468185760225, 35.736035211197191],
              [-80.321015119099059, 35.733697811200841],
              [-80.322813319096269, 35.732874211202102],
              [-80.324022585760929, 35.730584677872457],
              [-80.325178185759171, 35.724608011215025],
              [-80.32287451909616, 35.725843611213008],
              [-80.324336519093833, 35.723371011216955],
              [-80.323159785762357, 35.719523011222748],
              [-80.321222719098671, 35.718766477890767],
              [-80.321195319098706, 35.71773587789238],
              [-80.319314519101624, 35.71677307789389],
              [-80.318751519102477, 35.718765211224024],
              [-80.3175443191044, 35.718421077891321],
              [-80.317882519103932, 35.71674947789387],
              [-80.315748519107217, 35.716611077894015],
              [-80.317827585770601, 35.715123477896327],
              [-80.316033919106758, 35.710885877902911],
              [-80.313929185776658, 35.709304677905493],
              [-80.315473719107672, 35.709030611239086],
              [-80.317894519103788, 35.701085211251552],
              [-80.322050919097364, 35.699461277920591],
              [-80.321911785764257, 35.697697811256717],
              [-80.326769585756665, 35.696303077925563],
              [-80.329129319086462, 35.693899411262692],
              [-80.33008511908497, 35.691724211266035],
              [-80.328042319088013, 35.681555277948405],
              [-80.327369119089155, 35.680822077949585],
              [-80.32686338575661, 35.681508877948488],
              [-80.326496785757172, 35.684188277944429],
              [-80.326275585757514, 35.679104011285517],
              [-80.321814185764367, 35.677063877955334],
              [-80.319538319101241, 35.680566611283439],
              [-80.320214919100295, 35.676399011289789],
              [-80.316903585772025, 35.675595811291032],
              [-80.313390919110873, 35.681021611282574],
              [-80.312740119111879, 35.687227277939712],
              [-80.310945385781338, 35.685005011276417],
              [-80.308111385785764, 35.683881211278276],
              [-80.305442319123244, 35.68665067794052],
              [-80.307625585786411, 35.693522277929958],
              [-80.305045119123861, 35.691230611266803],
              [-80.301563385795873, 35.691938411265653],
              [-80.303582319126065, 35.69439001126193],
              [-80.30262691912759, 35.695259811260541],
              [-80.304084985791974, 35.697230077924132],
              [-80.301951719128567, 35.696793611258101],
              [-80.300686585797223, 35.69851041125554],
              [-80.302396985794587, 35.700870411251856],
              [-80.300007919131588, 35.70359401124756],
              [-80.300989519130042, 35.704739677912528],
              [-80.297198719135963, 35.704966211245448],
              [-80.295455785805416, 35.707186411242105],
              [-80.297617985802049, 35.706867277909168],
              [-80.299413185799267, 35.708677611239693],
              [-80.303174919126661, 35.709527411238412],
              [-80.301823585795489, 35.713007411232923],
              [-80.303170385793408, 35.714130477897982],
              [-80.303335919126482, 35.717336677892945],
              [-80.305047585790362, 35.718734677890836],
              [-80.30470718579096, 35.72223827788531],
              [-80.306419519121732, 35.723178411217191],
              [-80.306024985788895, 35.724666677881601],
              [-80.307287785786968, 35.725675011213241],
              [-80.306500519121585, 35.726819611211511],
              [-80.307144585787228, 35.728606277875372],
              [-80.305767519122696, 35.729681811207115],
              [-80.304001119125473, 35.726497477878695],
              [-80.304873119124068, 35.72505527788104],
              [-80.303274385793202, 35.722924477884192],
              [-80.302631985794221, 35.71916841122345],
              [-80.299321185799386, 35.716463877894228],
              [-80.298482719134029, 35.712478611233735],
              [-80.295678519138335, 35.708972811239278],
              [-80.288735785815788, 35.715357211229275],
              [-80.292757185809592, 35.709932611237832],
              [-80.292309185810268, 35.708833077906093],
              [-80.293938919141056, 35.707757877907738],
              [-80.293465319141831, 35.704345277913148],
              [-80.297678119135298, 35.703111611248289],
              [-80.299958985798412, 35.696288611258979],
              [-80.301279385796363, 35.695281811260543],
              [-80.300999985796807, 35.693884611262717],
              [-80.297912319134866, 35.69365361126296],
              [-80.296367119137301, 35.694820477927806],
              [-80.294452719140224, 35.699926077919997],
              [-80.292010185810739, 35.699832677920085],
              [-80.293442185808544, 35.699696277920339],
              [-80.294427585806943, 35.697063411257716],
              [-80.293614185808224, 35.696307211258897],
              [-80.295411919138758, 35.695483877926904],
              [-80.294880119139634, 35.693926277929279],
              [-80.302491119127808, 35.690587877934433],
              [-80.302578319127576, 35.687404677939469],
              [-80.297752919135178, 35.684653411276997],
              [-80.304123185791923, 35.686443677940758],
              [-80.305641519122901, 35.683650677945252],
              [-80.304716585791027, 35.682070077947685],
              [-80.307158585787192, 35.682117277947668],
              [-80.30822631911883, 35.680972877949444],
              [-80.308902185784461, 35.678385411286627],
              [-80.307668119119626, 35.677377077954986],
              [-80.310981519114534, 35.67573007795761],
              [-80.310674119115106, 35.674103877960022],
              [-80.304358919124866, 35.674420811292919],
              [-80.311208785780821, 35.672363677962778],
              [-80.309778719116423, 35.670966011298219],
              [-80.306578719121489, 35.671605277963977],
              [-80.302314585794704, 35.669747677966768],
              [-80.302876719127198, 35.668877811301456],
              [-80.300272519131227, 35.662967677977406],
              [-80.301086185796635, 35.663105677977114],
              [-80.302126585794952, 35.660839077980711],
              [-80.298428185800788, 35.655111411322935],
              [-80.299462185799143, 35.659371677982904],
              [-80.29769438580189, 35.65927887798307],
              [-80.297584185802009, 35.657355211319384],
              [-80.294964585806156, 35.666972011304381],
              [-80.29223811914369, 35.670886011298364],
              [-80.290528185813002, 35.669006877968002],
              [-80.293448519141862, 35.667566277970138],
              [-80.29466111913996, 35.662025011312153],
              [-80.291601319144718, 35.66287021131086],
              [-80.293960919141, 35.660673411314178],
              [-80.293148319142233, 35.659573611315921],
              [-80.29483231913963, 35.659254077983121],
              [-80.294187785807367, 35.658314677984436],
              [-80.295366385805551, 35.658407211317808],
              [-80.296071585804384, 35.654789211323305],
              [-80.294445319140323, 35.653711811325081],
              [-80.291442585811581, 35.654007411324471],
              [-80.296410785803857, 35.652270277993978],
              [-80.294253119140592, 35.649681011331211],
              [-80.292260385810323, 35.650229277997084],
              [-80.293019585809077, 35.648718411332766],
              [-80.292093119143942, 35.649198611331997],
              [-80.28889911914888, 35.645211478004853],
              [-80.287298319151319, 35.646653011335957],
              [-80.287182785818231, 35.649584277998031],
              [-80.285045119154802, 35.654002677991286],
              [-80.284654119155505, 35.652490877993557],
              [-80.282520319158778, 35.653634277991898],
              [-80.285835785820268, 35.649675011331226],
              [-80.284827719155203, 35.648048211333787],
              [-80.280844519161349, 35.647357878001571],
              [-80.285502585820836, 35.646651678002684],
              [-80.288760985815657, 35.643264811341226],
              [-80.288426519149652, 35.641317878010966],
              [-80.280659785828277, 35.63769361134996],
              [-80.272946319173627, 35.636794011351355],
              [-80.270726919177037, 35.639173811347518],
              [-80.2705281191773, 35.641051478011263],
              [-80.273862919172188, 35.643894211340182],
              [-80.270217519177891, 35.642585611342213],
              [-80.268780919180074, 35.646752278002452],
              [-80.268733119180126, 35.64063767801207],
              [-80.270477119177428, 35.637295678017267],
              [-80.264083719187397, 35.63534327802023],
              [-80.26186438585745, 35.637493878016755],
              [-80.26530991918537, 35.641092611344675],
              [-80.264718385853087, 35.642763678008691],
              [-80.260898985859058, 35.645439611337849],
              [-80.262609319189721, 35.646219811336664],
              [-80.260251319193344, 35.647087811335325],
              [-80.261536985858015, 35.650409811330235],
              [-80.255151319201218, 35.64300641134173],
              [-80.254421185869035, 35.643417811340953],
              [-80.257836119197123, 35.648596877999637],
              [-80.255391185867552, 35.651182277995645],
              [-80.25757591919745, 35.653680677991645],
              [-80.253169519204334, 35.654477677990485],
              [-80.256085319199826, 35.655969277988277],
              [-80.258102319196723, 35.658192677984687],
              [-80.257988119196852, 35.659452077982849],
              [-80.253222319204156, 35.656584677987325],
              [-80.253357585870731, 35.659768011315634],
              [-80.251816319206398, 35.658392411317834],
              [-80.248881919211044, 35.659747077982388],
              [-80.248604785878058, 35.662174811311957],
              [-80.246866385880764, 35.663138277976941],
              [-80.247345119213378, 35.664237211308659],
              [-80.246279785881711, 35.664925211307491],
              [-80.248555919211526, 35.666938277971099],
              [-80.246254585881729, 35.666940411304438],
              [-80.245247519216662, 35.669094211301115],
              [-80.246456385881459, 35.670650211298721],
              [-80.250532985875054, 35.671307211297687],
              [-80.252133985872604, 35.670507277965669],
              [-80.249969185876012, 35.672909677961911],
              [-80.251086385874203, 35.676231477956662],
              [-80.258897185862054, 35.670789011298496],
              [-80.257153719198129, 35.672985811295177],
              [-80.258554119195992, 35.674910811292079],
              [-80.256534119199102, 35.674496611292795],
              [-80.257177719198069, 35.675779611290807],
              [-80.254340519202515, 35.677357077954923],
              [-80.25534678586763, 35.679991611284208],
              [-80.25349651920385, 35.678524077953227],
              [-80.252535719205355, 35.682759811279993],
              [-80.253601119203722, 35.683516611278833],
              [-80.250962185874414, 35.683697011278468],
              [-80.252279119205696, 35.685209877942782],
              [-80.249760719209576, 35.684502077943932],
              [-80.248021919212363, 35.685534277942281],
              [-80.249207985877149, 35.690388077934813],
              [-80.25322151920426, 35.692836811264328],
              [-80.250693919208118, 35.693498277929962],
              [-80.254088719202912, 35.694830077927861],
              [-80.251533185873541, 35.695354077926993],
              [-80.251361385873793, 35.697437877923903],
              [-80.257511585864222, 35.696161811259117],
              [-80.259616119194334, 35.696851011258047],
              [-80.258913585862103, 35.697331211257278],
              [-80.260481785859611, 35.699920477920045],
              [-80.258738585862375, 35.70156767791741],
              [-80.259438185861256, 35.703079811248358],
              [-80.255958119200045, 35.702229011249699],
              [-80.254076519202954, 35.702639277915694],
              [-80.25382198586999, 35.703761211247354],
              [-80.251127519207557, 35.7032316112481],
              [-80.249397385876819, 35.704540611246102],
              [-80.250165919208996, 35.70762747790792],
              [-80.251316585873894, 35.707926411240805],
              [-80.251286385873982, 35.70914021123906],
              [-80.250162785875602, 35.709436677905217],
              [-80.252429985872141, 35.713996277898104],
              [-80.251581785873441, 35.717636611225828],
              [-80.255118519201346, 35.718602211224379],
              [-80.252814719204878, 35.719309811223241],
              [-80.251996519206102, 35.721713411219412],
              [-80.250985385874401, 35.721804011219319],
              [-80.252552585871967, 35.72519487788071],
              [-80.249538785876553, 35.724120611215767],
              [-80.249598719209871, 35.72661667787861],
              [-80.247689519212827, 35.727007877877952],
              [-80.244549785884317, 35.731087211204908],
              [-80.247696719212797, 35.731885677870309],
              [-80.249103185877345, 35.73341867786803],
              [-80.24891038587765, 35.736006677863998],
              [-80.244898319217157, 35.739193811192308],
              [-80.246337719214921, 35.743909877851593],
              [-80.243303185886305, 35.743523477852307],
              [-80.239419585892392, 35.752656811171505],
              [-80.237647919228436, 35.751330077840123],
              [-80.236020319230988, 35.752614011171488],
              [-80.236695585896598, 35.753369211170366],
              [-80.235684319231495, 35.75343887783697],
              [-80.235517519231735, 35.754721411168248],
              [-80.23641818589698, 35.755980077832987],
              [-80.23428391923369, 35.756600411165266],
              [-80.233725585901141, 35.759303077827781],
              [-80.231953319237221, 35.757587077830408],
              [-80.234085719233974, 35.755455477833834],
              [-80.232988185902286, 35.754128277835889],
              [-80.235626119231597, 35.751973211172469],
              [-80.234782185899576, 35.751195277840338],
              [-80.237915585894598, 35.74778787784561],
              [-80.241118385889763, 35.747922411178706],
              [-80.242123119221503, 35.743295611185999],
              [-80.240752319223645, 35.747281477846343],
              [-80.239684185891974, 35.746824477847099],
              [-80.243015585886781, 35.738691677859663],
              [-80.242476319220884, 35.734570077866124],
              [-80.241013319223214, 35.73280807786881],
              [-80.236882985896273, 35.731575211204245],
              [-80.240870585890036, 35.731136411204886],
              [-80.246086385881938, 35.725406411213783],
              [-80.246421519214778, 35.724123611215816],
              [-80.245100585883506, 35.723437877883498],
              [-80.247035919213829, 35.72178721121935],
              [-80.248067985878947, 35.7169998778935],
              [-80.247028185880481, 35.716359811227846],
              [-80.248485985878176, 35.714778211230225],
              [-80.247469119213179, 35.710611211236596],
              [-80.245701319215925, 35.711437411235352],
              [-80.247802785879287, 35.708458211240043],
              [-80.248525519211569, 35.703442211247761],
              [-80.257395185864368, 35.698795277921647],
              [-80.249445785876674, 35.699250477920941],
              [-80.24998858587594, 35.695650211259931],
              [-80.248396919211757, 35.692312477931807],
              [-80.246794719214222, 35.690917211267333],
              [-80.245701985882647, 35.692383877931718],
              [-80.243873919218743, 35.689981077935386],
              [-80.239079519226152, 35.694290811262078],
              [-80.242325585887841, 35.686868011273532],
              [-80.244261985884748, 35.686545611274028],
              [-80.250351985875341, 35.679184877952139],
              [-80.249778719209644, 35.677792077954336],
              [-80.245371385883118, 35.67722381128857],
              [-80.244446985884508, 35.678438411286606],
              [-80.244190185884918, 35.675553277957704],
              [-80.239639519225364, 35.672649077962376],
              [-80.237565319228509, 35.674734877959111],
              [-80.238298785894131, 35.677459477954869],
              [-80.236528985896825, 35.676316011289885],
              [-80.235186985898963, 35.680210411283838],
              [-80.232717385902788, 35.680533077950031],
              [-80.231873719237399, 35.679228477951995],
              [-80.229802119240617, 35.683650077945174],
              [-80.228114985909883, 35.681178211282372],
              [-80.229853319240476, 35.679619477951519],
              [-80.229739185907306, 35.678176877953661],
              [-80.226593785912257, 35.676599277956143],
              [-80.228753985908895, 35.675864611290706],
              [-80.232740319236086, 35.676502611289663],
              [-80.23341078590164, 35.674120277959958],
              [-80.231893985904037, 35.673136811294853],
              [-80.235233519232224, 35.672950677961751],
              [-80.235989185897665, 35.671255411297807],
              [-80.235258919232024, 35.670866811298367],
              [-80.23727891922897, 35.670361211299166],
              [-80.236435785897015, 35.669514477967198],
              [-80.238176119227546, 35.669742011300173],
              [-80.239658385891971, 35.666007811305974],
              [-80.241846519221951, 35.665364611306927],
              [-80.239936185891509, 35.663969477975741],
              [-80.23984991922498, 35.662366411311609],
              [-80.236762985896462, 35.662392077978268],
              [-80.236368119230349, 35.660812277980597],
              [-80.23987578589157, 35.660786211314075],
              [-80.242067119221588, 35.66261621131116],
              [-80.242117519221495, 35.658562811317552],
              [-80.244359185884718, 35.656293411321087],
              [-80.242227185887998, 35.656730611320313],
              [-80.243965119218558, 35.655400677989007],
              [-80.243739119218958, 35.654416077990675],
              [-80.245984119215507, 35.654528477990425],
              [-80.246288985881733, 35.651894611327862],
              [-80.247606719212911, 35.651252011328836],
              [-80.24429571921803, 35.651163611329025],
              [-80.248780785877784, 35.648296611333365],
              [-80.246226519215156, 35.647612078001146],
              [-80.250011519209124, 35.646527811336227],
              [-80.249252785877047, 35.645044211338529],
              [-80.250799119208011, 35.645246078004845],
              [-80.250744985874746, 35.643963678006912],
              [-80.252205319205871, 35.643140678008137],
              [-80.251870785873052, 35.641697678010416],
              [-80.254113919202894, 35.642593078008986],
              [-80.256389185866055, 35.64060307801202],
              [-80.25235191920558, 35.639087411347646],
              [-80.251851185873079, 35.636407478018555],
              [-80.248141719212128, 35.633549011356251],
              [-80.248952719210934, 35.631876411358917],
              [-80.246904719214058, 35.631764011358996],
              [-80.24768151921279, 35.625831878034887],
              [-80.24661531921447, 35.625626811368647],
              [-80.245216719216671, 35.628353278031057],
              [-80.245521985882874, 35.626085878034587],
              [-80.244595119217649, 35.625147811369345],
              [-80.242267719221275, 35.625722611368417],
              [-80.243189985886488, 35.623248411372231],
              [-80.245012585883615, 35.622948878039381],
              [-80.24495398588374, 35.6212772113754],
              [-80.242880119220274, 35.622378478040332],
              [-80.240859719223465, 35.621647478041439],
              [-80.240946385889913, 35.623456678038735],
              [-80.23841818589392, 35.620688011376217],
              [-80.239369719225749, 35.619152678045339],
              [-80.236731519229863, 35.617826878047424],
              [-80.236364119230302, 35.615720211384087],
              [-80.238409719227263, 35.614481678052528],
              [-80.236694719229945, 35.611322878057422],
              [-80.235603185898299, 35.61295001138825],
              [-80.234564719233219, 35.612263811389425],
              [-80.234373385900199, 35.616157211383268],
              [-80.235664185898202, 35.616636878049292],
              [-80.236088385897517, 35.619270211378478],
              [-80.233790119234413, 35.620440078043373],
              [-80.233988319234072, 35.621905611374473],
              [-80.231265919238353, 35.620488211376596],
              [-80.230762919239169, 35.621954211374259],
              [-80.233007519235628, 35.622570678040063],
              [-80.231915185904029, 35.623762411371445],
              [-80.230792919239093, 35.623442678038657],
              [-80.229478185907737, 35.626329478034165],
              [-80.228018119243359, 35.625071211369402],
              [-80.223000385917885, 35.627663011365541],
              [-80.228966985908585, 35.621223011375378],
              [-80.228234119243041, 35.618315078046578],
              [-80.224672385915198, 35.618478278046382],
              [-80.224026119249572, 35.617356811381455],
              [-80.216288519261639, 35.620202278043735],
              [-80.208440519273722, 35.625498011368848],
              [-80.209676119271876, 35.626871211366677],
              [-80.213657519265666, 35.625448611368824],
              [-80.20381931928091, 35.632943678023992],
              [-80.203064519282066, 35.635715211353045],
              [-80.204468319279897, 35.63690521135112],
              [-80.203936385947429, 35.638188011349087],
              [-80.199449719287713, 35.640160211346029],
              [-80.201409985951386, 35.636380411351979],
              [-80.197257785957845, 35.635833078019516],
              [-80.197370785957617, 35.636794878017895],
              [-80.18713811930678, 35.645846211337243],
              [-80.185342319309655, 35.645503478004457],
              [-80.186549319307744, 35.646533478002823],
              [-80.185736519309046, 35.647656011334391],
              [-80.181248985982677, 35.65008561133061],
              [-80.183718585978738, 35.650702677996435],
            ],
            [
              [-80.380447519006793, 35.715001477896521],
              [-80.378509919009787, 35.716971011226917],
              [-80.378846919009163, 35.718070211225154],
              [-80.383087319002698, 35.719329811223133],
              [-80.386569385663847, 35.718642477890853],
              [-80.380447519006793, 35.715001477896521],
            ],
            [
              [-80.08365098613416, 35.46336801162056],
              [-80.082558986135723, 35.463963011619512],
              [-80.079449319473952, 35.467877811613562],
              [-80.08387298613377, 35.466665811615428],
              [-80.08365098613416, 35.46336801162056],
            ],
            [
              [-80.089868586124396, 35.456752011630726],
              [-80.088020919460632, 35.457438278296308],
              [-80.086115786130335, 35.460620878291365],
              [-80.088327319460234, 35.460369678291727],
              [-80.090903386122875, 35.458263678295111],
              [-80.089868586124396, 35.456752011630726],
            ],
            [
              [-80.103124186103912, 35.488702278247843],
              [-80.100659519441081, 35.490327678245364],
              [-80.102339186105155, 35.491404411577037],
              [-80.102843586104314, 35.490236478245492],
              [-80.10480338610131, 35.491267478243913],
              [-80.10597971943281, 35.490191478245492],
              [-80.103124186103912, 35.488702278247843],
            ],
            [
              [-80.325270185758995, 35.713180477899357],
              [-80.328385319087602, 35.716021611228314],
              [-80.332624785747726, 35.717328611226321],
              [-80.330295585751287, 35.714717011230277],
              [-80.325270185758995, 35.713180477899357],
            ],
            [
              [-80.383536319002019, 35.714474677897329],
              [-80.383395985668869, 35.714680811230323],
              [-80.390528918991095, 35.718459077891112],
              [-80.387467718995822, 35.715298877896089],
              [-80.383536319002019, 35.714474677897329],
            ],
            [
              [-80.371658119020367, 35.716833411227071],
              [-80.367024585694253, 35.717199477893132],
              [-80.365507985696581, 35.717817611225541],
              [-80.373483319017623, 35.717726611225658],
              [-80.371658119020367, 35.716833411227071],
            ],
            [
              [-80.228566719242565, 35.615017211385066],
              [-80.227052385911577, 35.615316078051194],
              [-80.225343585914231, 35.61687467804893],
              [-80.228512385909255, 35.616643078049265],
              [-80.229548385907663, 35.615153678051627],
              [-80.228566719242565, 35.615017211385066],
            ],
            [
              [-80.162201986012121, 35.478212478264027],
              [-80.162342786011948, 35.480388078260717],
              [-80.166234986005975, 35.481326011592614],
              [-80.166234919339331, 35.481142811592917],
              [-80.162201986012121, 35.478212478264027],
            ],
            [
              [-80.376403785679656, 35.715161877896321],
              [-80.371658119020367, 35.716009011228323],
              [-80.371686185687054, 35.716169277894721],
              [-80.37570171901416, 35.716902211226909],
              [-80.376403785679656, 35.715161877896321],
            ],
            [
              [-80.208368585940491, 35.581344811437418],
              [-80.208538519273588, 35.58308527810135],
              [-80.210698785936927, 35.584663878098922],
              [-80.209238585939204, 35.582306211435764],
              [-80.208368585940491, 35.581344811437418],
            ],
            [
              [-80.238536785893757, 35.750146077841976],
              [-80.238366785894016, 35.749024077843842],
              [-80.23643871923025, 35.750506811174773],
              [-80.237646785895095, 35.750620211174578],
              [-80.238536785893757, 35.750146077841976],
            ],
            [
              [-80.256425719199285, 35.616648478049171],
              [-80.258666919195832, 35.618299611380053],
              [-80.259312585861437, 35.617888011380614],
              [-80.258696985862457, 35.616971278048709],
              [-80.256425719199285, 35.616648478049171],
            ],
            [
              [-80.356155585711122, 35.721938411219071],
              [-80.353965119047871, 35.72221281121864],
              [-80.353852719048064, 35.722533411218194],
              [-80.355228385712564, 35.723426877883469],
              [-80.356155585711122, 35.721938411219071],
            ],
            [
              [-80.352560719050075, 35.722716277884501],
              [-80.351942585717723, 35.72360927788327],
              [-80.353992519047893, 35.724182411215509],
              [-80.35388031904796, 35.723563877883237],
              [-80.352560719050075, 35.722716277884501],
            ],
            [
              [-80.359442985706096, 35.715687011228852],
              [-80.357954585708342, 35.715892877895158],
              [-80.356803119043491, 35.716762877893757],
              [-80.360594185704258, 35.716007811228394],
              [-80.359442985706096, 35.715687011228852],
            ],
            [
              [-80.292675319142973, 35.62959561136239],
              [-80.291552319144671, 35.630510878027678],
              [-80.292925719142659, 35.631542411359533],
              [-80.293432119141755, 35.630054078028422],
              [-80.292675319142973, 35.62959561136239],
            ],
            [
              [-80.100129386108563, 35.485632878252545],
              [-80.099176986110024, 35.486617411584348],
              [-80.101108919440321, 35.486595011584484],
              [-80.10110918610701, 35.485953811585432],
              [-80.100129386108563, 35.485632878252545],
            ],
            [
              [-80.25655871919912, 35.602862078070586],
              [-80.25532411920102, 35.603387611403207],
              [-80.256135785866377, 35.604464811401556],
              [-80.256893585865214, 35.603938811402259],
              [-80.25655871919912, 35.602862078070586],
            ],
            [
              [-80.231710719237583, 35.617350278048093],
              [-80.230869519238922, 35.617534278047856],
              [-80.230899719238892, 35.619343478044982],
              [-80.231796719237479, 35.618861811379134],
              [-80.231710719237583, 35.617350278048093],
            ],
            [
              [-80.257934385863507, 35.639207611347501],
              [-80.256810719198711, 35.64023707801266],
              [-80.256781719198727, 35.6408552780116],
              [-80.258803319195636, 35.639735278013404],
              [-80.257934385863507, 35.639207611347501],
            ],
            [
              [-80.379183785675366, 35.714818277896825],
              [-80.377583319011194, 35.714932677896684],
              [-80.377218185678487, 35.715345077896018],
              [-80.378453785676584, 35.71598627789507],
              [-80.379183785675366, 35.714818277896825],
            ],
            [
              [-80.32136518576516, 35.715766477895329],
              [-80.320550585766398, 35.716201277894697],
              [-80.322178319097247, 35.717530211225949],
              [-80.321982185764227, 35.716751477893865],
              [-80.32136518576516, 35.715766477895329],
            ],
            [
              [-80.261389185858206, 35.61704267804862],
              [-80.260266985860028, 35.617270611381628],
              [-80.259817985860707, 35.617545011381196],
              [-80.261472319191398, 35.617821411380817],
              [-80.261389185858206, 35.61704267804862],
            ],
            [
              [-80.244996519216954, 35.729254811207852],
              [-80.243845985885514, 35.729874211206834],
              [-80.243369585886228, 35.730721877872099],
              [-80.244520585884459, 35.730331611206168],
              [-80.244996519216954, 35.729254811207852],
            ],
            [
              [-80.318221919103337, 35.713543611232183],
              [-80.31847371910294, 35.714757411230266],
              [-80.319428719101495, 35.714254011231048],
              [-80.318979919102219, 35.713635477898606],
              [-80.318221919103337, 35.713543611232183],
            ],
            [
              [-80.426639985601753, 35.714236211231025],
              [-80.426331185602123, 35.714305011231033],
              [-80.425826585602977, 35.716022877895057],
              [-80.426893318934731, 35.71519787789623],
              [-80.426639985601753, 35.714236211231025],
            ],
            [
              [-80.376431785679642, 35.716833611227059],
              [-80.376516119012877, 35.717383211226206],
              [-80.377723585677643, 35.717726677892301],
              [-80.37789211901071, 35.716948077893619],
              [-80.376431785679642, 35.716833611227059],
            ],
            [
              [-80.364440919031608, 35.717771677892301],
              [-80.362531385701288, 35.718183677891602],
              [-80.362531319034531, 35.718412677891195],
              [-80.364890185697618, 35.718161077891693],
              [-80.364440919031608, 35.717771677892301],
            ],
            [
              [-80.161725586012949, 35.476930211599438],
              [-80.161222119347087, 35.477800478264726],
              [-80.162621919344929, 35.477891811597999],
              [-80.162173586012273, 35.477250811598935],
              [-80.161725586012949, 35.476930211599438],
            ],
            [
              [-80.39412498565224, 35.727046611211279],
              [-80.394040785652294, 35.727504611210463],
              [-80.395388985650243, 35.727710477876769],
              [-80.395388985650243, 35.727527277877073],
              [-80.39412498565224, 35.727046611211279],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 47951.0,
        COMID: 9250140,
        FDATE: "1999/09/13 00:00:00.000",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        AREASQKM: 5.685,
        ELEVATION: 314.0,
        REACHCODE: "03040101003388",
        FTYPE: "LakePond",
        FCODE: 39009,
        Shape_Leng: 0.64706231182861396,
        Shape_Area: 0.00056905922234600002,
        ONOFFNET: 1,
        PurpCode: null,
        PurpDesc: null,
        MeanDepth: 5.114621985,
        LakeVolume: 29075093.22,
        MaxDepth: 18.82463143,
        MeanDUsed: 5.114621985,
        MeanDCode: "4",
        LakeArea: 5684700.2858100003,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-81.227482117692034, 36.135756077243514],
              [-81.226885584359593, 36.133031410581111],
              [-81.229480184355509, 36.131036810584135],
              [-81.226454984360316, 36.12751267725622],
              [-81.2274681843586, 36.125244810593188],
              [-81.228545184357017, 36.128518477254715],
              [-81.229505517688892, 36.128884077254213],
              [-81.23043551768734, 36.127715410589246],
              [-81.231596584352303, 36.130485277251694],
              [-81.232469784350883, 36.129041810587296],
              [-81.231678317685521, 36.128263810588464],
              [-81.235092984346863, 36.127573677256123],
              [-81.236473984344684, 36.126175477258357],
              [-81.234974984347048, 36.124070077261592],
              [-81.236271317678415, 36.122557410597324],
              [-81.237884917675842, 36.125739077259027],
              [-81.239945784339284, 36.125920210592085],
              [-81.240224317672244, 36.123446810595965],
              [-81.241213384337357, 36.124155677261399],
              [-81.244003784333017, 36.121496477265566],
              [-81.249476984324588, 36.119910677268138],
              [-81.243611517666864, 36.123397477262642],
              [-81.242428984335504, 36.125391077259565],
              [-81.247676717660624, 36.123691077262208],
              [-81.251996917653969, 36.126624010590945],
              [-81.252114517653808, 36.123486810595921],
              [-81.253861384317759, 36.125664010592345],
              [-81.256260917647296, 36.12555187725934],
              [-81.254231317650522, 36.123672010595612],
              [-81.257447317645529, 36.124957610593583],
              [-81.258210384310871, 36.124294210594655],
              [-81.25668838431335, 36.12275841059693],
              [-81.258606784310359, 36.123424410595874],
              [-81.257311784312378, 36.121087277266156],
              [-81.259201984309414, 36.121684477265319],
              [-81.260559717640717, 36.119670610601645],
              [-81.259179384309505, 36.1178828772712],
              [-81.26211258430493, 36.119351410602235],
              [-81.265981117632236, 36.118209877270772],
              [-81.266462917631486, 36.116699010606453],
              [-81.26324611763647, 36.116146410607257],
              [-81.267849317629384, 36.114089477277105],
              [-81.264776117634142, 36.111773677280723],
              [-81.264836384300622, 36.108980010618438],
              [-81.26204571763833, 36.106641610621978],
              [-81.265036717633734, 36.106942010621594],
              [-81.266025917632192, 36.105843677289954],
              [-81.26633018429834, 36.110446877282641],
              [-81.268361917628567, 36.110631877282515],
              [-81.267090584297137, 36.111615477280964],
              [-81.267681584296213, 36.112829810612425],
              [-81.269910984292835, 36.11303781061207],
              [-81.27152118429035, 36.111848410613845],
              [-81.269146117627372, 36.115235610608636],
              [-81.271120917624216, 36.115832810607799],
              [-81.271175984290835, 36.116977810605874],
              [-81.276906117615283, 36.116387210606945],
              [-81.278602517612569, 36.113984010610579],
              [-81.276853717615381, 36.113181010611811],
              [-81.279055117611904, 36.11315987727852],
              [-81.280525117609727, 36.111054077281722],
              [-81.278325784279787, 36.109334877284425],
              [-81.281316717608433, 36.109932677283439],
              [-81.282447984273404, 36.107826677286823],
              [-81.281151584275392, 36.106222677289338],
              [-81.281435917608235, 36.104436610625498],
              [-81.278330917613118, 36.105167077290957],
              [-81.278672317612575, 36.102785610627961],
              [-81.277006584281821, 36.103356810627133],
              [-81.277431384281101, 36.102120610628901],
              [-81.276022117616662, 36.100837010630869],
              [-81.280397717609844, 36.099329010633312],
              [-81.278339784279694, 36.097655677302555],
              [-81.27828658427984, 36.094930410640075],
              [-81.279727984277599, 36.092939210643181],
              [-81.277473717614384, 36.090464210647156],
              [-81.27959038427781, 36.089985010647808],
              [-81.281059984275544, 36.087902077317835],
              [-81.28249838427331, 36.088292477317111],
              [-81.282638184273083, 36.089391877315506],
              [-81.279617517611086, 36.090923877313116],
              [-81.28105418427549, 36.092940210643292],
              [-81.286501517600357, 36.091318410645727],
              [-81.28985698426186, 36.093313210642691],
              [-81.285117517602544, 36.092622677310487],
              [-81.281645184274566, 36.094154410641238],
              [-81.280654717609423, 36.09660401063752],
              [-81.282428317606616, 36.100269477298582],
              [-81.284235117603941, 36.099606677299505],
              [-81.283274384272033, 36.100682277297892],
              [-81.283779784271303, 36.102949877294407],
              [-81.281916384274155, 36.103727077293172],
              [-81.282874517606047, 36.104964410624575],
              [-81.284200984270626, 36.104782210624762],
              [-81.286151384267612, 36.101783610629468],
              [-81.288208984264429, 36.104052410626025],
              [-81.285951584267877, 36.103913410626262],
              [-81.284115117604074, 36.105995877289729],
              [-81.287330984265736, 36.107120410621235],
              [-81.285636917601721, 36.107760410620187],
              [-81.285522117601829, 36.109638077283932],
              [-81.286565917600342, 36.109959477283553],
              [-81.286000317601179, 36.110989610615263],
              [-81.287070917599522, 36.112730810612618],
              [-81.288933784263293, 36.11252607727954],
              [-81.287013117599599, 36.114058877277216],
              [-81.290343117594489, 36.114175810610277],
              [-81.287886917598257, 36.11495267727571],
              [-81.287772984265018, 36.115914477274259],
              [-81.289833184261909, 36.116236610607075],
              [-81.28884431759667, 36.117128877272421],
              [-81.292030117591764, 36.120612077267026],
              [-81.294119184255237, 36.120178277267712],
              [-81.295167517586947, 36.11621721060709],
              [-81.293785517589129, 36.115162810608808],
              [-81.296070784252208, 36.116034610607414],
              [-81.297230117583695, 36.113905610610686],
              [-81.303605517573828, 36.117298677272117],
              [-81.303072317574674, 36.113909077277299],
              [-81.30462558423892, 36.112604810612652],
              [-81.298165384248989, 36.109692410617185],
              [-81.300169784245895, 36.108915077285076],
              [-81.298985917580978, 36.107425877287426],
              [-81.302004384242935, 36.108595610618977],
              [-81.305081184238247, 36.107887477286738],
              [-81.31058151756298, 36.111485877281041],
              [-81.314137517557526, 36.111510610614459],
              [-81.316113784221102, 36.110389410616222],
              [-81.313431317558639, 36.112380477279771],
              [-81.310947784229143, 36.112218877279872],
              [-81.303499984240659, 36.108871277285175],
              [-81.301918717576484, 36.109923810616976],
              [-81.305924317570202, 36.112147477280189],
              [-81.303890184240004, 36.11452787727643],
              [-81.304253984239551, 36.117917410604377],
              [-81.302785784241792, 36.118557677270189],
              [-81.299936917579487, 36.116563677273291],
              [-81.296436784251682, 36.11697367727254],
              [-81.295756184252582, 36.120225210600836],
              [-81.292226317591542, 36.121917410598371],
              [-81.288954984263228, 36.119144277269186],
              [-81.287599917598698, 36.119486810602098],
              [-81.287995984264739, 36.118571077270076],
              [-81.286895917599679, 36.117837410604693],
              [-81.285737384268259, 36.118981677269574],
              [-81.285710384268327, 36.11790527727112],
              [-81.284440517603628, 36.117744077271482],
              [-81.285401584268811, 36.116485210606697],
              [-81.281027584275591, 36.115840810607722],
              [-81.279810317610782, 36.118977210602907],
              [-81.281191717608635, 36.120398010600582],
              [-81.279781317610798, 36.11959547726849],
              [-81.277747784280621, 36.120670210600167],
              [-81.277266784281437, 36.121769010598541],
              [-81.278365784279629, 36.123212610596283],
              [-81.281638317607928, 36.124749610593824],
              [-81.286183384267588, 36.124340677261102],
              [-81.287395917599042, 36.125486477259415],
              [-81.287421517599, 36.127799610589136],
              [-81.289367984262583, 36.12899167725385],
              [-81.288948584263267, 36.125144210593248],
              [-81.291461717592711, 36.124458877260906],
              [-81.294534317587932, 36.12867467725448],
              [-81.294220984255048, 36.131376610583629],
              [-81.293631584255934, 36.12803287725535],
              [-81.290077584261553, 36.12523661059322],
              [-81.290383984261041, 36.129290077253529],
              [-81.28914158426295, 36.129586877253018],
              [-81.286743117599997, 36.128646277254404],
              [-81.28618238426759, 36.125210877259917],
              [-81.280874917609083, 36.125756477259017],
              [-81.277178984281534, 36.124219277261375],
              [-81.276866384281959, 36.12598241059186],
              [-81.275456317617568, 36.124950677260244],
              [-81.275993784283401, 36.123966410595074],
              [-81.274837584285137, 36.123049477263123],
              [-81.275714317617144, 36.121722010598546],
              [-81.274840184285097, 36.121011277266291],
              [-81.269615917626538, 36.122839010596806],
              [-81.269810584292884, 36.125129210593229],
              [-81.268712517627989, 36.122952677263299],
              [-81.263997717635334, 36.123704277262107],
              [-81.267295984296823, 36.126913210590544],
              [-81.265941717632245, 36.126385277257953],
              [-81.265601584299532, 36.127507210589613],
              [-81.266612117631325, 36.131675877249847],
              [-81.265007984300439, 36.12812501058869],
              [-81.262244784304755, 36.125740677258989],
              [-81.261282184306197, 36.127869610589073],
              [-81.260803784306972, 36.126838610590596],
              [-81.257640917645233, 36.127728677255959],
              [-81.257468384312006, 36.129789677252745],
              [-81.256623917646777, 36.128208677255145],
              [-81.252359917653337, 36.129120477253707],
              [-81.253311717651968, 36.134548810578565],
              [-81.251031317655475, 36.13037867725177],
              [-81.246190784329599, 36.130219277252081],
              [-81.243058584334506, 36.131069877250695],
              [-81.243853117666617, 36.133725477246571],
              [-81.241675717669921, 36.131323077250329],
              [-81.237976984342424, 36.130845810584503],
              [-81.236114517678573, 36.131305610583638],
              [-81.238261584341956, 36.132425610581947],
              [-81.236623917677832, 36.132175277249075],
              [-81.236654184344445, 36.133709610580013],
              [-81.234280117681351, 36.13165081058321],
              [-81.230304517687614, 36.135272677244188],
              [-81.227482117692034, 36.135756077243514],
            ],
          ],
        ],
      },
    },
    {
      type: "Feature",
      properties: {
        OBJECTID: 47879.0,
        COMID: 9224427,
        FDATE: "1999/10/07 00:00:00.000",
        RESOLUTION: "Medium",
        GNIS_ID: null,
        GNIS_NAME: null,
        AREASQKM: 0.215,
        ELEVATION: 0.0,
        REACHCODE: "03040103002083",
        FTYPE: "LakePond",
        FCODE: 39004,
        Shape_Leng: 0.033532164655333999,
        Shape_Area: 2.1391974822e-5,
        ONOFFNET: 0,
        PurpCode: null,
        PurpDesc: null,
        MeanDepth: null,
        LakeVolume: null,
        MaxDepth: null,
        MeanDUsed: null,
        MeanDCode: null,
        LakeArea: null,
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-80.136890986051526, 35.454926211633506],
              [-80.13705918605126, 35.457514078296185],
              [-80.138654719382032, 35.457651411629286],
              [-80.133868386056179, 35.460170811625517],
              [-80.133644519389918, 35.461201278290559],
              [-80.135939719386272, 35.461247011623755],
              [-80.133644519389918, 35.462392211622046],
              [-80.133812519389608, 35.463468478286984],
              [-80.130173519395271, 35.460651678291242],
              [-80.130593519394608, 35.459163211627015],
              [-80.131321186060177, 35.459804411626067],
              [-80.134344186055387, 35.45836161162822],
              [-80.136890986051526, 35.454926211633506],
            ],
          ],
        ],
      },
    },
  ],
};
